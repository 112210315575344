.new_limit_wrap {
    width: calc(100% - 900px);
    height: calc(100vh - 700px);
    position: absolute;
    translate: -50% -50%;
    top: 50%;
    left: 50%;
    background-color: var(--white);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.text {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    color: var(--header-color);
    font-size: 16px;
    font-family: var(--primary-font-family);
    flex-wrap: wrap;
}

.button_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}