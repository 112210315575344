.form_title {
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 24px;
}

.top_text {
    position: relative;
    text-align: center !important;
}

.intro_text_body {
    display: flex !important;
    justify-content: center !important;
    padding: 8px !important;
}

.intro_text {
    font-size: 18px !important;
    font-weight: 600 !important;
    color: var(--header-color) !important;
    font-family: var(--primary-font-family) !important;
}

.back_icon {
    position: absolute !important;
    left: 0 !important;
    padding: 0px !important;
    margin: 8px 0px 0px 0px !important;
}

.time_wrapper {
    display: flex;
    align-items: center;
    color: var(--table-header-font);
    font-size: 14px;
    margin: 10px 0;
}

.date_icon {
    width: 15px !important;
    height: 15px !important;
    color: var(--table-header-font) !important;
    margin: 0 10px 0px 0;
}

.standard_icon {
    width: 15px;
    height: 15px;
    margin: 0 10px 0px 0;
}

.top_border {
    margin-top: 10px !important;
    border-bottom: 2px solid var(--border-color) !important;
}

.schedule_textarea {
    margin-bottom: 15px;
    padding: 11.8px 11px 11.79px 11px;
    width: 100%;
    border: 1px solid #E6E7E9;
    border-radius: 3px;
    font-size: 14px,
}

.schedule_textarea:hover {
    border: 1px solid var(--primary) !important;
}

.schedule_textarea:focus {
    outline: none;
    border: none;
    background-color: #F6F7FA;
}

.form_body {
    width: 100%;
    margin-top: 10px;
    overflow-y: auto;
    padding: 0px 20px 20px 20px;
}

.form_container {
    margin-top: 10px;
    line-height: 30px;
    border-bottom: 2px solid var(--border-color);
}

.input_container {
    margin: 15px 0px;
}

.add_guests {
    background-color: var(--primary);
    padding: 8px 16px;
    text-transform: capitalize !important;
}

.schedule_button {
    width: 100%;
    background-color: var(--primary);
    border-radius: 40px !important;
    padding: 8px 16px;
    margin-bottom: 20px;
    text-transform: capitalize !important;
}