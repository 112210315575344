.pdf-viewer {
    width: 100%;
    height: 100%;
    background-color: #FFF;
}

.pdf-header {
    width: 100%;
}

.top-bar {
    text-align: left;
    padding: 0px 5px 0px 5px;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-color);
    background: #ffffff;
    width: 100%;
    height: 65px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
}

.menu-icons {
    width: 40px !important;
    height: 40px !important;
    margin: 4px;
display: block;
    color: var(--primary);
    cursor: pointer;
    margin: 8px 5px 0px 0px;
}

.pdf-top-bar-right-side {
    display: flex;
    align-items: center;
    width: 27%;
}

.title-sec {
    margin-left: 10px;
    width: 100% !important;
}

.title-sec .doc-title input {
    font-size: 12px;
    color: var(--table-header-color);
    border: none;
    font-weight: 600;
    text-overflow: ellipsis;
    display: inline-flex;
    width: 70% !important;
    padding: 5px 15px 5px 0 !important;
    align-items: flex-start;
    gap: 5.05px;
}

.title-sec .doc-title input:focus {
    outline: 1px solid var(--primary);
    width: 70% !important;
    border-radius: 2px;
    border: none;
}

.top-bar .pdf-button {
    float: right;
    margin-top: 1px;
    display: flex;
    align-items: center;
}

.recipient-body {
    float: right;
    margin-top: 1px;
}

.document_modified_time {
    margin: 5px 0 0 0;
    font-size: 12px;
    font-weight: 500;
    color: var(--table-header-color);
}

.btn-save {
    padding: 6px 16px !important;
    text-transform: capitalize !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    border-radius: 4px !important;
    cursor: pointer !important;
    z-index: 1 !important;
    font-family: var(--primary-font-family) !important;
    margin-right: 7px !important;
}

.send-doc-img {
    width: 17px;
    height: 17px;
    flex-shrink: 0;
}

.btn-save-proceed {
    /* padding: 10px 16px; */
    margin-right: 7px !important;
    text-transform: capitalize !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    border-radius: 4px !important;
    cursor: pointer !important;
    z-index: 1;
    font-family: var(--primary-font-family) !important;
    white-space: nowrap !important;
}

.doc-mod-time p {
    margin: 2px 0 0 3px;
    font-size: 12px;
    font-weight: 500;
}

.doc-mod-time p span {
    color: #62637a;
    font-weight: 500;
}

.pdf-paginator {
    width: 80px;
    margin: 0 auto;
    border: 1px solid #E5EAEE;
    border-radius: 20px;
    font-weight: 400;
    display: inline-block;
}

.pdf-viewer__header {
    text-align: center;
    padding: 10px;
    margin-left: 5px;
    position: relative;
    background: #ffffff;
    border-bottom: 1px solid #e4e4e4;
}

.sign-select {
    text-align: left;
    margin: 0;
    overflow: visible;
    z-index: 1;
    opacity: 1;
    width: 160px;
    height: 35px;
    vertical-align: top;
    border: 1px solid #e0d7ff;
    border-radius: 5px;
    font-size: 13px !important;
    cursor: pointer;
    background: #fbfafe;
    font-weight: 500;
}


.sign-text {
    color: #5b4ead !important;
    font-size: 13px !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    text-overflow: clip;
    border: none;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: none;
}


.add-recipient {
    text-transform: capitalize !important;
    padding: 6px 16px 6px 16px !important;
    font-size: 14px !important;
    font-family: var(--primary-font-family);
    border-radius: 6px !important;
    font-weight: 400 !important;
    margin-right: 7px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    white-space: nowrap !important;
    z-index: 1 !important;
}

.add_recipients {
    margin-left: 5px !important;
    color: var(--primary) !important;
    font-size: 13px !important;
    letter-spacing: normal;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #e0d7ff !important;
    border-width: 0px !important;
}

.sign-select svg {
    color: var(--primary);
}

.recipient-img {
    width: 14px;
    height: 14px;
}

.document-back-img {
    width: 20px;
    height: 20px;
}

.select-items {
    background-color: #FFFFFF !important;
    border: 1px solid var(--border-color) !important;
    padding: 5px;
    margin-right: 0;
    width: 38.5px;
    height: 36.5px;
}

.more_icon_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: relative;
}

.more_icon_button:hover {
    background-color: #ffffff14;
}

.select_more_options li {
    max-height: 50px;
    min-height: 40px;
}

.viewer_condition_icon_button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: relative !important;
}

.select-container {
    width: 213px;
    height: 290px;
    opacity: 0 !important;
    position: absolute !important;
    transform: rotate(-90.04deg);
    flex-shrink: 0;
    background-color: #FFF !important;
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, .18) !important;
    align-items: center;
}

.select-header {
    color: #8C98A4;
    display: flex;
    width: 56.66px;
    height: 12.5px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    font-family: var(--primary-font-family);
    font-size: 10.5px;
    font-style: normal;
    font-weight: 600;
    line-height: 15.75px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin: 20px 0px 10px 30px;
}

.select-body {
    width: 196px !important;
    height: 36px !important;
    margin: 0px 0px 0px 7px !important;
    display: flex;
    border-radius: 8px;
    align-items: center;
    cursor: pointer;
}

.select-body:hover {
    background-color: #f6f7fa;
    width: 190px !important;
    height: 36px !important;
    margin: 0px 0px 0px 7px !important;
    display: flex;
    border-radius: 8px;
    align-items: center;
    cursor: pointer;
}

.save-template {
    font-family: var(--primary-font-family);
    color: var(--table-font-color);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.5px;
    margin-left: 12px;
}

.avatar {
    border: 1px solid #377DFF !important;
    width: 40px;
    height: 40px;
    background-color: #fff !important;
}

.css-hpl3kc-MuiAvatarGroup-root .MuiAvatar-root {
    z-index: 0 !important;
}

.document-back {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    /* box-shadow: 0 0 10px 0 #c8c8c8; */
}

/* avatar img size */

.css-1pqm26d-MuiAvatar-img {
    width: 70% !important;
    height: 70% !important;
}

.css-1hy9t21 {
    width: 70% !important;
    height: 70% !important;
}

.doc_new_text {
    font-size: 16px;
    font-weight: 600px;
    font-family: var(--primary-font-family);
    color: var(--header-color);
    display: flex;
    justify-content: center;
}