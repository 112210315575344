.reusable_select_top ul {
    padding: 0px 0px !important;
}

.reusable_select_items {
    padding: 0px 10px !important;
    font-size: 12px;
    min-height: 37px !important;
}

.reusable_select_top:focus {
    /* border: 1px solid; */
}

.reusable_label {
    font-family: 'Inter' !important;
    padding-top: .675rem;
    padding-bottom: .675rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
    color: #1e2022;
    width: 150px;
}

