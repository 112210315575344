.grid_container {
    /* display: grid;
    grid-template-columns: 38% 49%;
    gap: 10px;  */
  }
  
  .grid_item {
    width: 530px;
    padding: 20px 0px;
    /* border: 1px solid #ccc;  */
  }
  


  .grid_item::-webkit-scrollbar {
    width: 0;
    background: transparent;
}
  .grid_text_label{
    display: grid;
    grid-template-columns: 38% 60%;
    gap: 10px;
    margin-bottom: 1.5rem !important;
  }