.login_page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  top: 50%;
}

.login_header {
  font-size: 36px !important;
  color: var(--table-font-color) !important;
  font-family: var(--primary-font-family) !important;
  margin-bottom: 20px !important;
  letter-spacing: 0.15px !important;
}

.login_button {
  padding: 10px 20px !important;
  border-radius: 6px !important;
  background-color: var(--primary);
  text-transform: capitalize !important;
  font-size: 14px !important;
  cursor: pointer;
}

.create_button {
  padding: 10px 20px !important;
  border-radius: 6px !important;
  background-color: var(--primary);
  text-transform: capitalize !important;
  font-size: 14px !important;
  cursor: pointer;
}

.monday_reload_modal {
  position: absolute !important;
  translate: -50% -50% !important;
  top: 50% !important;
  left: 50% !important;
  width: 400px !important;
  background-color: #fff !important;
  padding: 30px !important;
  border-radius: 10px !important;
  outline: none !important;
}

.monday_reload_btn {
  text-transform: capitalize !important;
  padding: 10px 30px !important;
  font-family: var(--primary-font-family) !important;
}

.reload_text {
  font-size: 16px !important;
  font-family: var(--primary-font-family) !important;
  color: var(--table-font-color) !important;
  display: flex !important;
  justify-content: center !important;
  font-weight: 400 !important;
}

.reload_button {
  display: flex !important;
  justify-content: center !important;
  padding-top: 20px !important;
  gap: 20px !important;
}

.centilio_sign {
  font-weight: 600 !important;
  font-size: 22px !important;
  margin-left: 10px !important;
  font-style: normal !important;
  font-family: var(--primary-font-family) !important;
  color: var(--header-color) !important;
  line-height: 30px !important;
  letter-spacing: 0.188px !important;
}

.refresh_header {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding-bottom: 20px !important;
}

.tooltip_text {
  font-size: 12px !important;
  margin: 10px;
  font-family: var(--primary-font-family) !important;
  line-height: 17px;
}

.next_btn {
  font-size: 12px !important;
  margin: 0px 10px 10px 10px !important;
  padding: 2px !important;
  cursor: pointer !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.monday_not_available {
  position: absolute;
  display: flex;
  align-items: center;
  translate: -50% -50%;
  top: 50%;
  left: 50%;
}

.monday_app_text {
  padding-left: 5px;
  color: var(--header-color);
  font-weight: 400;
  font-size: 18px;
  font-family: var(--primary-font-family);
}