.main_wrap {
    padding: 0px 30px 30px;
}

.crm_main_wrap {
    padding: 0px 30px 30px;
    background-color: #13214440 !important;
    height: 100%;
}

.custom_dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.new_contact_wrap {
    width: 500px;
    position: absolute;
    translate: -50% -50%;
    top: 50%;
    left: 50%;
    background-color: var(--white);
    border-radius: 10px;
    padding: 40px;
}

.close {
    position: absolute !important;
    top: 8px;
    right: 8px;
}

.sign_journey {
    color: var(--header-color) !important;
    font-weight: 600 !important;
    font-size: 18px !important;
}