.new_limit_wrap {
    width: calc(100% - 700px);
    height: calc(100vh - 70px);
    position: absolute;
    translate: -50% -50%;
    top: 50%;
    left: 50%;
    background-color: var(--white);
    border-radius: 10px;
    padding: 10px;
}

.doc_viewer_wrapper_ {
    width: 100%;
    height: calc(100vh - 200px);
    display: flex;
    justify-content: center;
}

.doc_viewer_wrapper {
    width: 100%;
    height: calc(100vh - 130px);
    display: flex;
    justify-content: center;
    overflow-y: scroll;
}

.doc_pdf_viewer {
    height: calc(100vh - 192px);
}

.doc_viewer_wrapper::-webkit-scrollbar-track {
    background-color: inherit;
}

.doc_viewer_wrapper::-webkit-scrollbar {
    max-height: 12px;
    max-width: 5px;
    min-height: 8px;
    min-width: 5px;
    background-color: #ffffff;
}

.doc_viewer_wrapper::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px #0000004d;
    background: #b8b8b8;
    border-radius: 5px;
    width: 5px;
    height: 5px;
}

.close_btn {
    position: absolute !important;
    top: 10px;
    right: 10px;
    margin-left: 5px;
    z-index: 1 !important;
}