.main_wrap {
  padding: 10px 20px 10px 20px;
  overflow-y: auto;
  height: calc(100vh - 65px);
}

.main_wrap::-webkit-scrollbar-track {
  background-color: inherit;
}

.main_wrap::-webkit-scrollbar {
  max-height: 12px;
  max-width: 5px;
  min-height: 8px;
  min-width: 5px;
  background-color: #ffffff;
}

.main_wrap::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px #0000004d;
  background: #b8b8b8;
  border-radius: 5px;
  width: 5px;
  height: 5px;
}

.card {
  padding: 21.88px 20px 21.36px 20px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 10px;
  cursor: pointer;
}

.card:hover {
  padding: 21.88px 20px 21.36px 20px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 10px;
  box-shadow: var(--box-shadow) !important;
  cursor: pointer;
}

.welcome_used_wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  cursor: pointer;
}

.welcome_used_wrap h1,
h2 {
  margin: 0;
}

.welcome_card {
  background-color: var(--primary) !important;
  color: var(--white) !important;
  gap: -0.25px;
  padding: 21.88px 20px 21.36px 20px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 10px;
  cursor: pointer;
}

.welcome_card:hover {
  background-color: var(--primary) !important;
  color: var(--white) !important;
  gap: -0.25px;
  padding: 21.88px 20px 21.36px 20px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0 0.1875rem 0.75rem #b4c8ec !important;
  border-radius: 10px;
  cursor: pointer;
}

.used_storage h2 {
  margin: 5px 0;
}

.used_storage {
  border: 1px solid var(--border-color);
  box-shadow: 0 0.375rem 0.75rem rgba(140, 152, 164, 0.075);
}

.welcome_card,
.used_storage {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.signed_new_doc_wrap {
  display: flex;
  gap: 20px;
  padding-top: 20px;
}

.signed_new_doc_wrap h2 {
  margin: 5px 0;
}

.docs_share_wrap {
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 40px;
}

.docs_share_wrap h1,
h2 {
  margin: 5px 0;
}

.docs_share_wrap div {
  box-shadow: none !important;
}

.docs_share_wrap h2 {
  font-weight: 700;
  font-size: 46px;
}

.shared_name {
  font-weight: 500 !important;
  font-size: 16px !important;
  font-family: var(--primary-font-family) !important;
  color: var(--common-title);
  letter-spacing: 0.15px !important;
}

@media (max-width: 900px) {
  .welcome_used_wrap {
    grid-template-columns: 1fr;
  }
}

.progress_container {
  margin-bottom: 20px;
  border-radius: 4px;
  box-shadow: 0px 6px 12px 0px rgba(140, 152, 164, 0.08);
  border: 1px solid rgba(231, 234, 243, 0.70);
  background-color: #FAFAFB;
}

.start_centilio {
  color: var(--table-font-color);
  font-family: var(--primary-font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
}

.start_fields {
  display: flex;
  padding: 11.88px 82.26px 11.87px 11.87px;
  align-items: center;
  gap: 7px;
  border-radius: 8px;
  border: 1px solid  rgba(56, 124, 255, 0.40);
  cursor: pointer;
  width: 100%;
}

.start_fields:hover {
  display: flex;
  padding: 11.88px 82.26px 11.87px 11.87px;
  align-items: center;
  gap: 7px;
  border-radius: 8px;
  border: 1px solid  rgba(56, 124, 255, 0.40);
  box-shadow: var(--box-shadow);
  cursor: pointer;
  width: 100%;
}

.complete_fields {
  display: flex;
  padding: 11px;
  align-items: center;
  gap: 7px;
  border-radius: 8px;
  border: 1px solid rgba(56, 124, 255, 0.40);
  cursor: pointer;
  width: 100%;
}

.complete_fields:hover {
  display: flex;
  padding: 11px;
  align-items: center;
  gap: 7px;
  border-radius: 8px;
  border: 1px solid rgba(56, 124, 255, 0.40);
  cursor: pointer;
  box-shadow: var(--box-shadow);
  width: 100%;
}

/* #171A1F */
.field_name {
  font-family: var(--primary-font-family);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: var(--table-font-color);
  display: flex;
  padding-right: 0px;
  flex-direction: column;
  align-items: flex-start;
}

.field_description {
  color: var(--table-header-font);
  font-family: var(--primary-font-family);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin-top: 5px;
}

.centilio_container {
  height: 54px;
  padding: 19px 19px 15px 22px;
  border-radius: 4px;
  font-size: 12px;
  display: flex;
  margin-bottom: 6px;
  gap: 20px;
  align-items: center;
  width: 100%;
}

.signed_total {
  font-weight: 400 !important;
  color: var(--header-color) !important;
}

.signed_total_doc {
  font-weight: 400 !important;
  color: #fff !important;
}

.welcome_text {
  font-family: var(--primary-font-family) !important;
  color: #FFF !important;
  font-size: 32px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
}

.used_storage_text {
  font-family: var(--primary-font-family) !important;
  color: var(--header-color) !important;
  font-size: 32px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
}

.signed_doc {
  font-style: normal !important;
  font-family: var(--primary-font-family) !important;
}

.storage_bottoma_text {
  font-style: normal !important;
  font-family: var(--primary-font-family) !important;
  color: var(--header-color) !important;
}

.doc_shared {
  color: var(--header-color);
  font-family: var(--primary-font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.menuprocess {
  color: var(--primary);
  width: 38.5px;
  height: 38.5px;
  background-color: #ECF2FF;
  border-radius: 50%;
  display: flex;
  margin-left: 5px;
  justify-content: center;
  align-items: center;
}

.menuProcess_active_icon {
  background-color: #ECF2FF;
  width: 38.5px;
  height: 38.5px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.active_menuprocess {
  background-color: #387CFF;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vector_icon_img {
  object-fit: contain;
  width: 10px;
  height: 7.5px;
}