.wrapper {
    height: 100%;
    margin: 0 auto;
    text-align: center;
    width: 100%;
}

.upsec_area {
    width: 100%;
    height: 360px;
    border: 1px dashed var(--primary);
    border-radius: 5px;
    background: #f8f7ff;
    margin-bottom: 20px;
    position: relative;
}

.browse_inn_part {
    width: 100%;
    height: 100%;
    position: relative;
}

.before_upload {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.before_upload>span {
    color: var(--table-font-color);
}

.box_img {
    width: 89px;
    height: 137px;
    flex-shrink: 0;
}

.upload_file_text {
    color: var(--table-font-color);
}

.upload_inprogress {
    position: relative;
    transform: translate(0%, 70%);
}

.up_text {
    margin: 20px 0 10px;
    font-size: 16px;
    font-weight: 500;
    color: var(--table-font-color);
}

.up_hint {
    color: #b5b5b5 !important;
    font-weight: 400 !important;
    font-size: 14px !important;
}

.btn {
    border-radius: 6px !important;
    text-transform: capitalize !important;
    display: inline-block;
    height: 42.49px !important;
    border: none;
    color: var(--white);
    margin-top: 20px !important;
    cursor: pointer !important;
}

.header_title {
    text-align: left;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    justify-content: space-between;
}

.text_sample {
    color: var(--table-header-font);
    font-weight: 400;
    font-size: 14px;
    display: flex;
    justify-content: flex-end;
    padding-top: 1px;
}

.file_download {
    padding-left: 5px;
    color: var(--primary);
    font-weight: 900;
    cursor: pointer;
}


.title {
    display: inline-block;
    margin: 0 !important;
    padding: 20px 0 10px 0px !important;
}

.header_text {
    color: var(--header-color) !important;
    font-weight: 600;
    font-size: 22px;
    font-family: var(--primary-font-family);
    line-height: 28px;
}

.supported_formats {
    padding: 30px 0;
    font-family: var(--primary-font-family);
}

.formats {
    color: var(--table-font-color);
    font-weight: 500;
    font-size: 14px;
    display: flex;
    justify-content: center;
}

.formats_group {
    color: var(--table-header-font);
    font-weight: 400;
    font-size: 14px;
    margin-left: 5px;
}

.loader_wrap {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.btn_samble_box {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    margin: 20px 0px 10px 0px;
}

.sample_icon {
    width: 20px;
}