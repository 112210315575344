.filter_top{
    border-radius: 50% 0% 0% 50%;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px 0 #c8c8c8;
    display: flex;
    text-align: end;
    background: white;
   
}
.filters_array{
    max-height: 400px;
    overflow: scroll;
    scroll-behavior: smooth;
}
.filters_array::-webkit-scrollbar {
    width: 0;
    background: transparent;
}

.filters_array::-webkit-scrollbar-thumb {
    background: #FF0000;
}

.selectors{
    padding: 10px;
    width: auto;
    gap:6px;
    display: flex;
    justify-content: space-around;
    
}
.top_selecters{
    width: 850px;
    padding: 20px;
    background: white;
    box-shadow: 0 0 6px 0 #c8c8c8;
}
.top_selecters_none{
    display: none;
}


.filter_hide{
    display: none;
    position: absolute;
    -webkit-transition: all 0.5s ease-out;
    top: 14%;
    z-index: 10;
    right: -1000px;
}

.filter_open{
    display: flex;
    position: absolute;
    -webkit-transition: all 0.5s ease-out;
    top: 14%;
    z-index: 10;
    right: 0px;
}
.filter_close{
    display: flex;
    position: absolute;
    -webkit-transition: all 0.5s ease-out;
    top: 14%;
    z-index: 10;
    right: 0px;
}
.filter_sub_button{
    display: flex;
    justify-content: space-between;
    padding: 15px;
}

