.submit_btn{
    text-align: end;
    padding: 10px;
  }
  .submit_btn button{
    text-transform: capitalize;
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid rgba(149, 157, 165, 0.2);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
 
  .submit_btn img{
    margin-right: 10px;
}
.submit_btn .save_drive{
color: black;
font-family: revert;
}

.submit_btn .sent_sign{
color: rgb(255, 255, 255);
font-family: revert;
background-color: #377DFF;
}
.submit_btn .sent_sign:hover{
    color: rgb(255, 255, 255);
    font-family: revert;
    background-color: #377DFF;
    border-color: black;
    }
