.pdf_header_wrapper {
    width: 100%;
    position: fixed;
}

.sign-top-bar {
    text-align: left;
    padding: 0px 5px 0px 5px;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-color);
    background: #ffffff;
    width: 100%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;

}

.sign-request-bar {
    display: flex;
    padding: 0px 10px 0px 10px;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-color);
    background: #ffffff;
    width: 100%;
    height: 55px;
    flex-shrink: 0;
    align-items: center;
    position: relative;
}

.top-bar-right-side {
    display: flex;
    align-items: center;
}

.menu-icons {
    width: 40px !important;
    height: 40px !important;
    margin: 4px;
    display: block;
    color: var(--primary);
    cursor: pointer;
    margin: 8px 5px 0px 0px;
}

.title-sec .doc-title input {
    font-size: 12px;
    color: #62637A;
    border: none;
    font-weight: 600;
    text-overflow: ellipsis;
    display: inline-flex;
    padding: 0px 288.95px 0.5px 0px;
    align-items: flex-start;
    gap: 5.05px;
}

.title-sec .doc-title input:focus {
    outline: 1px solid var(--primary);
    border-radius: 2px;
    border: none;
}

.document_modified_time {
    margin: 5px 0 0 0px;
    font-size: 12px;
    font-weight: 500;
    color: var(--table-header-font);
}

.top-bar .pdf-button {
    float: right;
    margin-top: 1px;
    margin-right: 11px;
    display: flex;
    align-items: center;
}

.pdf-button>div {
    font-weight: 500;
    background: var(--primary);
    color: #fff;
    display: inline-flex;
    margin-right: 7px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}

.pdf-button>div.btn-save {
    padding: 10px 35px !important;
    width: 108px !important;
    height: 39px !important;
    z-index: 1 !important;
    background-color: var(--primary) !important;
    text-transform: capitalize !important;
}

.doc-title-document-name {
    font-size: 13px;
    background: unset;
    border: none;
    padding: 3px;
    font-weight: 600;
    border-radius: 2px;
    padding-left: 0;
    color: var(--table-font-color);
}

.doc-title-document-name::first-letter {
    text-transform: uppercase;
}

.select-items {
    background-color: #FFFFFF !important;
    border: 1px solid rgba(76, 61, 171, 0.32);
    padding: 5px;
    margin-right: 0;
    width: 38.5px;
    height: 36.5px;
}

.select-container {
    width: 213px;
    height: 290px;
    opacity: 0 !important;
    position: absolute !important;
    transform: rotate(-90.04deg);
    flex-shrink: 0;
    background-color: #FFF;
    box-shadow: rgba(140, 152, 164, 0.18);
    align-items: center;
}

.sign-request-document {
    background-color: #fff !important;
    color: var(--table-font-color) !important;
    border: 1px solid var(--border-color) !important;
    height: 37px !important;
    font-size: 14px !important;
}

.information-container {
    border-radius: 8px !important;
    background: #FFF !important;
    box-shadow: var(--box-shadow) !important;
}

.dropdown-container-pdf2 {
    border-radius: 8px !important;
    box-shadow: 0 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, .175) !important;
}

.sign-select-option {
    text-align: left;
    margin: 0;
    z-index: 1;
    opacity: 1;
    vertical-align: top;
    border-radius: 5px;
    font-size: 13px !important;
    font-family: var(--primary-font-family) !important;
    cursor: pointer;
    color: var(--table-font-color);
    border: none;
    background: none;
}

.sign-text {
    display: block;
    font-size: 14px;
    z-index: 1;
    margin: 0;
    border: unset;
    text-align: left;
    padding: 10px 12px;
    opacity: 1;
    cursor: pointer;
    color: var(--table-font-color) !important;
}

.sign-document-wrapper {
    width: 100% !important;
    height: calc(100vh - 70px) !important;
    background-color: #F8FAFD !important;
    display: flex;
    /* background-color: green;
    color: green; */
}

.sign-request-pdf {
    width: 100% !important;
    height: calc(100vh - 70px) !important;
    overflow: scroll;
    margin-right: 2px !important;
}

.sign-request-pdf::-webkit-scrollbar-track {
    background-color: inherit;
}

.sign-request-pdf::-webkit-scrollbar {
    max-height: 8px !important;
    max-width: 5px !important;
    min-height: 8px !important;
    min-width: 5px !important;
    background-color: #ffffff !important;
}

.sign-request-pdf::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px #0000004d;
    background: #b8b8b8;
    border-radius: 5px;
    width: 3px !important;
    height: 3px !important;
}

.pdf-viewer-sign {
    display: flex;
    margin-top: 40px;
    margin-bottom: 100px;
}

.scrolling-page {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
}

.sign-paginator {
    text-align: center;
    width: 90px;
    border: 1px solid #E5EAEE;
    border-radius: 20px;
    font-weight: 400;
    display: inline-block;
}

.sign-page-input {
    width: 30px;
    padding: 6px 2px 6px 6px;
    text-align: center;
    font-size: 16px;
    border: none;
    background: none;
    font-weight: 500;
    outline: none;
    font-family: var(--primary-font-family);
}

.pdf-sign-field {
    display: inline-block;
    list-style: none;
    border: 1px solid #4b3cab33;
    vertical-align: top;
    font-size: 12px;
    cursor: pointer;
    color: var(--table-font-color);
    text-align: initial;
    border-radius: 3px;
    user-select: none;
    font-weight: 500;
    outline-width: 0;
    z-index: 0;
}

.pdf-sign-field::placeholder {
    color: var(--primary);
    padding-left: 2px;
    opacity: 0.6;
}

.pdf_board_field {
    display: inline-block;
    list-style: none;
    border: 1px solid transparent;
    vertical-align: top;
    font-size: 12px;
    cursor: pointer;
    color: var(--table-font-color);
    text-align: initial;
    border-radius: 3px;
    user-select: none;
    font-weight: 500;
    outline-width: 0;
    z-index: 0;
}

.pdf_board_field:hover {
    border: 1px solid var(--field-border-color);
}

.pdf_board_field::placeholder {
    color: var(--primary);
    padding-left: 2px;
    opacity: 0.6;
}

.pdf_board_field_empty {
    display: inline-block;
    list-style: none;
    border: none;
    vertical-align: top;
    font-size: 12px;
    cursor: default;
    color: var(--table-font-color);
    text-align: initial;
    border-radius: 3px;
    user-select: none;
    font-weight: 500;
    outline-width: 0;
    z-index: 0;
}

.pdf-sign-field-required {
    display: inline-block;
    list-style: none;
    border: 1px solid #4b3cab33;
    vertical-align: top;
    font-size: 12px;
    cursor: pointer;
    color: var(--table-font-color);
    text-align: initial;
    border-radius: 3px;
    user-select: none;
    font-weight: 500;
    outline-width: 0;
    z-index: 0;
}

.pdf-sign-field-required::placeholder {
    color: var(--danger);
    padding-left: 2px;
}

input[type=number].pdf-sign-field::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.sign_radio {
    width: 17px;
    height: 17px;
    border: 1px solid #9a9a9a;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    cursor: move;
}


.radio-select {
    width: 12px;
    height: 12px;
    background-color: var(--primary) !important;
    color: var(--primary);
    border-radius: 50%;
    display: inline-block;
    transition: opacity 0.2s;
    border: none;
    cursor: move;
}

.icon-before {
    background: var(--primary);
    position: absolute;
    left: 0;
    top: 38%;
    height: 40%;
    width: 2px;
    content: "";
    transform: translateX(7px) rotate(-40deg);
    transform-origin: left bottom;
    border: none;
    cursor: move;
}

.icon-after {
    background: var(--primary);
    position: absolute;
    left: 0;
    bottom: 5px;
    height: 2px;
    width: 70%;
    content: "";
    transform: translateX(8px) rotate(-45deg);
    transform-origin: left bottom;
    cursor: move;
}

.custom-icon {
    border-radius: 0;
    width: 18px;
    height: 18px;
    line-height: unset;
    padding: 0;
    color: transparent;
    position: relative;
}

.checkbox-custom {
    background-color: #FFFFFF !important;
    /* border: 1px solid #e0d7ff !important; */
    border-radius: 5px !important;
    justify-content: space-around !important;
}

.select-sign-body {
    width: 110px !important;
    height: 20px !important;
    margin: 5px !important;
    min-height: 35px !important;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 6px !important;
}

.select-sign-body:has(:hover) {
    background-color: rgba(189, 197, 209, 0.2) !important
}

.css-hy0utx-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
    background-color: rgba(189, 197, 209, 0.2) !important;
}

.select-sign-body:hover {
    background-color: rgba(189, 197, 209, 0.2) !important;
    width: 110px !important;
    height: 20px !important;
    margin: 5px !important;
    display: flex;
    /* border-radius: 8px; */
    align-items: center;
    cursor: pointer;
}

.select-sign-body>span {
    font-family: var(--primary-font-family);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.5px;
}

.page-canvas {
    /* width: 80%; */
    margin: 0 auto;
    border: 1px solid var(--border-color);
    box-shadow: 0 0 10px -4px #c8c8c8;
}

.decline-mask {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #13214440;
    display: table;
    transition: opacity 0.3s ease;
    cursor: default;
}

.decline-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.decline-container {
    max-width: 600px;
    margin: 0px auto;
    background-color: #fff;
    border-radius: 10px;
    transition: all 0.3s ease;
}

.decline-body {
    padding: 40px;
}

.decline-text {
    font-size: 22px;
    font-weight: 600;
    margin: 0;
    color: var(--header-color);
}

.popup-close {
    width: 13px;
    height: 13px;
}

.decline-popup {
    cursor: pointer;
    margin: 15px 15px 0px 0px;
    opacity: .5;
    float: right;
    right: 15px;
    top: 15px;
    z-index: 1;
}

.decline-popup:hover {
    cursor: pointer;
    margin: 15px 15px 0px 0px;
    opacity: 1;
    float: right;
    right: 15px;
    top: 15px;
    z-index: 1;
}

.decline-textarea {
    padding-top: 20px
}

.decline-desc {
    width: 100%;
    height: 79px;
    padding: 10px 0;
    font-size: 14px;
    border: none;
    border: 0.0625rem solid var(--border-color);
    border-radius: 5px;
    padding: 10px;
    color: var(--table-font-color);
}

.decline-desc::placeholder {
    color: #a7a8ab6e;
}

.decline-desc:focus {
    outline: none !important;
    border: 1px solid var(--border-color);
}

.decline-buttons {
    padding-top: 20px;
}

.decline-message {
    padding-top: 5px;
}

.decline-submit {
    border-radius: 6px !important;
    display: inline-block;
    text-transform: capitalize !important;
}

.decline-success {
    position: relative;
    box-sizing: border-box;
    font-family: var(--primary-font-family);
    font-weight: 400;
    color: var(--table-font-color);
    font-size: 14px;
    padding-top: 15px;
}

.document-download {
    border: 1px solid #23ab94 !important;
    background-color: #fff !important;
    color: #23ab94 !important;
    border-radius: 6px !important;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    width: 130px !important;
    margin: 0 auto !important;
    text-transform: capitalize !important;
}

.signature_img {
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    color: #193037;
    font-weight: 400 !important;
    font-size: 14px !important;
}

.pdf-home {
    width: 35px;
}

.centilio-img {
    width: 30px;
    height: 30px;
    margin: 5px 0px 0px 2px;
    cursor: pointer;
}

.download-message {
    font-family: var(--primary-font-family);
    color: var(--table-font-color);
    font-size: 14px;
    /* line-height: 25px; */
}

.sign_pad_wrapper {
    width: fit-content !important;
    translate: 2000px !important;
    display: flex !important;
    align-items: center !important;
    min-height: 60 !important;
    font-size: 50 !important;
}

.sign-pdf-previewer {
    width: 15% !important;
    display: flex !important;
    justify-content: center !important;
    border-right: 1px solid var(--border-color) !important;
}

.sign-pdf-previewer-disable {
    width: 2% !important;
    background: #23ab94;
}