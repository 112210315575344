.popover_filter_top{
    display: flex;
    gap: 10px;
    padding-top: 10px;
}
.popover_filter_Delete{
    display: flex;
    align-items: center;
}
.popover_filter_Delete:hover{
  color: red;
  
}
.popover_filter_add_field{
    padding-top: 10px;
    display: flex;
    gap: 5px;
    color: var(--primary);
    font-weight: 600;
}