.admin_tool_top {
    width: 100%;
    height: 100%;
    /* padding: 20px 30px 30px 30px; */
    padding-top: 5px;
}

.admin_tool_top::-webkit-scrollbar {
    width: 0;
    background: transparent;
}

.admin_tool_top::-webkit-scrollbar-thumb {
    background: #FF0000;
}

.admin_tool_text_filed div{
    height: 35px;
}

.custom_dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.info_icon:hover {
fill: #1976d2;
cursor: pointer;
}

