.welcome-page {
  text-align: center;
  position: absolute;
  top: 49%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 30px;
  width: 100%;
}

.document-container::-webkit-scrollbar-track {
  background-color: inherit;
}

.document-container::-webkit-scrollbar {
  max-height: 12px;
  max-width: 5px;
  min-height: 8px;
  min-width: 5px;
  background-color: #ffffff;
}

.document-container::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px #0000004d;
  background: #b8b8b8;
  border-radius: 5px;
  width: 5px;
  height: 5px;
}

.document-container {
  width: 100%;
  height: 100%;
  padding: 0 30px;
  align-items: center;
  overflow-y: auto;
  height: calc(100vh - 130px);
}

.upload {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #cbd0e06e;
}

.my_doc_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.upload-text {
  color: #fff;
  font-weight: 500;
  padding: 14px 18px;
  display: inline-block;
  cursor: pointer;
  font-family: var(--primary-font-family);
  font-size: 14px;
}

.upload-body {
  display: flex;
  align-items: center;
}

.img-wrapper {
  max-width: 320px;
  margin: 0 auto;
}

.welcome-img {
  width: 320px;
  height: 244px;
}

.btn {
  padding: 0px;
  border-radius: 6px;
  background-color: var(--primary);
  display: inline-block;
  cursor: pointer;
  border: none;
}

.custom-file-upload {
  color: #fff;
  font-weight: 500;
  padding: 14px 18px;
  display: inline-block;
  cursor: pointer;
}

.input[type='file'] {
  display: none;
}

.document-header {
  text-align: left;
  padding: 5px 0;
}

.title {
  display: inline-block;
}

.my-documents {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
  font-family: var(--primary-font-family);
  line-height: 28px;
  color: var(--header-color);
}

.document-text {
  display: flex;
}

.favorites-body {
  text-align: left;
  margin-top: 15px;
}

.favorites-list {
  width: 200px !important;
  height: 180px;
  background: #ffffff;
  box-shadow: var(--box-shadow);
  border-radius: 4px;
  border: 1px solid var(--border-color);
  text-align: center;
  display: inline-block;
  border-radius: 5px;
  transition: 0.3s;
  max-width: 23%;
  cursor: pointer;
  position: relative;
  margin: 1%;
}

.skeleton-favorites-list {
  margin-left: 20px;
  width: 200px !important;
  height: 180px;
  border-radius: 4px;
  text-align: center;
  border-radius: 5px;
  transition: 0.3s;
  max-width: 23%;
  margin: 1%;
}

.favorites-list:hover {
  margin-left: 20px;
  background: #ffffff;
  width: 200px !important;
  border-radius: 4px;
  border: 1px solid #ECF2FF;
  text-align: center;
  display: inline-block;
  position: relative;
  border-radius: 5px;
  transition: 0.3s;
  max-width: 23%;
  box-shadow: 0 0.1875rem 0.75rem #b4c8ec !important;
  margin: 1%;
}

.css-bhp9pd-MuiPaper-root-MuiCard-root {
  box-shadow: none !important;
}

.thumbnail_body {
  background-color: var(--primary);
  border-radius: 5px !important;
  height: 100% !important;
}

.thumbnail_body .MuiCardContent-root {
  width: 100%;
  height: 100%;
  overflow: hidden !important;
  position: relative !important;
  padding: 0px !important;
}

.template-img {
  object-fit: fill;
  width: 100%;
  height: 100%;
}

.template-container {
  position: relative;
}

.document-name {
  font-family: var(--primary-font-family);
  font-size: 12px;
  line-height: 20px;
  color: #1e2022ff;
  text-align: center;
  margin-top: 2px;
  overflow: hidden;
  padding: 2px;
  text-overflow: ellipsis;
}

.document-time {
  font-family: var(--primary-font-family);
  font-size: 12px;
  line-height: 20px;
  color: #1e2022ff;
  text-align: center;
  overflow: hidden;
  margin-bottom: 10px;
  text-overflow: ellipsis;
}

.file-upload {
  text-align: left;
  margin: 0;
  z-index: 1;
  opacity: 1;
  vertical-align: top;
  border-radius: 5px;
  font-size: 14 px !important;
  cursor: pointer;
  color: #fff;
}

.upload-document {
  background-color: var(--primary) !important;
  color: #fff !important;
  height: 42.59px !important;
  font-size: 14px !important;
  border-radius: 0px 6px 6px 0px !important;
}

.upload-button {
  color: #fff !important;
  height: 42.59px !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
  border-radius: 6px 0px 0px 6px !important;
  box-shadow: none !important;
}

.upload-document:hover {
  background-color: rgb(38, 87, 178) !important;
}

.css-qiwgdb.MuiSelect-select {
  height: 0 !important;
}

.file-upload-wrapper {
  font-size: 13px !important;
  font-weight: 400 !important;
  width: 90% !important;
  border-radius: 6px !important;
  display: flex !important;
  margin-left: 9px !important;
}

.power-letter {
  padding-right: 30px !important;
  position: relative !important;
  display: inline-block !important;
}

.power-letter sup {
  position: absolute !important;
  top: -0.5em !important;
  font-size: 0.8em !important;
  padding-left: 3px !important;
  color: red !important;
}

.file-upload-svg {
  width: 17px;
  height: 17px;
  margin-right: 7px;
}

.trashIcon {
  width: 15px;
  height: 15px;
  cursor: pointer;
  color: #ff6868;
}

.delete-doc {
  z-index: 1;
  width: 25px;
  height: 25px;
  padding: 5px;
  border-radius: 3px;
  cursor: default;
  display: flex;
  position: absolute;
  right: 12px;
  top: -8px;
}

.folder-header {
  padding: 20px 0 20px;
  font-family: var(--primary-font-family);
  font-size: 15px;
  line-height: 24px;
  color: #1e2022ff;
  width: 100%;
}

.folder {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
}

#modal {
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  margin-right: '-50%';
  transform: translate(-70%, -30%) scale(0);
  transition: transform 0.5s ease-in-out;
  height: 25%;
  border-radius: 5px;
  z-index: 1;
  margin-bottom: 50px;
  border: 1px solid red;
  position: relative;
}

#modal.open {
  transform: translate(0);
}

.container {
  color: #ffffff;
  font-weight: 400;
  line-height: 20px;
  margin: 10px;
  height: auto;
  text-align: initial;
}

.user-text {
  line-height: 35px;
  font-size: 13px;
  color: #1e2022ff;
  font-family: var(--primary-font-family);
  padding: 3px;
}

.folder_name {
  font-size: 14px;
  font-family: var(--primary-font-family);
  margin-left: 1%;
  padding: 8px 0 8px;
}

.folder_main {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  padding-bottom: 20px;
}

.folder_main_wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
}

.folder_border {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  /* padding: 16px 10px 16px 19px; */
  background: #ffffffff;
  border-radius: 4px;
  color: #1e2022ff;
  font-size: 12px;
  align-items: center;
  width: 200px;
  height: 48px;
  border: .0625rem solid rgba(231, 234, 243, .7);
  box-shadow: 0 0.375rem 0.75rem rgba(140, 152, 164, 0.075);
  user-select: none !important;
  margin: 1%;
}

.folder_border:hover {
  cursor: pointer;
  box-shadow: var(--box-shadow);
}

.upload-body svg {
  color: #fff;
}

.thumnail_name {
  font-size: 13px !important;
  font-weight: 500 !important;
  font-family: var(--primary-font-family) !important;
  text-align: initial !important;
  color: #193037 !important;
  padding: 5px 15px 0px 20px !important;
  overflow: hidden !important;
  display: block !important;
  max-height: 24px !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.thumbnail_date {
  font-size: 11px !important;
  color: #101316 !important;
  text-align: initial !important;
  font-family: var(--primary-font-family) !important;
  padding: 1px 2px 5px 20px !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.css-46bh2p-MuiCardContent-root {
  padding: 0px !important;
}

.css-1qw96cp {
  padding: 0px !important;
}

.css-s18byi {
  box-shadow: none !important;
}

/* template img padding */
.css-pmf16o-MuiCardContent-root {
  padding: 0px !important;
}

.css-1c4yg90 {
  padding: 0px !important;
  height: 130px !important;
}

.css-4o55lv-MuiCardContent-root {
  padding: 0px !important;
  height: 130px !important;
}

/* select text overflow */

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding-right: 30px !important
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  height: 0px !important;
}

.no_documents_found {
  position: fixed;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  color: var(--table-font-color);
  font-family: var(--primary-font-family) !important;
}

.document_options {
  text-transform: capitalize !important;
  /* height: 0px !important; */
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  font-size: 13px !important;
  font-family: var(--primary-font-family) !important;
  color: var(--table-font-color) !important;
  margin-left: 10px !important;
}

.document_options.css-10fi992-MuiButtonBase-root-MuiButton-root {
  min-width: 0px !important
}

.doc_new_limit_wrap {
  width: 500px;
  position: absolute;
  translate: -50% -50%;
  top: 50%;
  left: 50%;
  background-color: var(--white);
  border-radius: 10px;
  padding: 50px;
}

.doc_close {
  position: absolute !important;
  top: 8px;
  right: 8px;
}

.doc_new_text {
  font-size: 14px;
  font-family: var(--primary-font-family);
  color: var(--table-font-color);
  font-weight: 600;
  padding-bottom: 20px !important;
}

.doc_new_textfield {
  display: flex !important;
  justify-content: space-between !important;
  gap: 5px;
  width: 100%;
  padding: 30px 0px 10px 0px;
  font-family: var(--primary-font-family);
  font-size: 16px;
  line-height: 25px;
  color: var(--table-font-color);
}

.doc_rename_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px !important;
  padding-top: 20px;
}

.doc_new_submit_btn {
  text-transform: capitalize !important;
  height: 42.59px !important;
  font-size: 14px !important;
  margin: 0px 10px;
  border-radius: 6px;
  display: inline-block !important;
  color: #fff;
  background-color: var(--primary) !important;
  cursor: pointer;
}

.document-delete-cancel {
  text-transform: capitalize !important;
  height: 42.59px !important;
  padding: 10.8px 17px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  margin: 0px 10px;
  border: 1px solid var(--border-color) !important;
  border-radius: 6px;
  display: inline-block;
  color: var(--table-header-font);
  cursor: pointer;
}

.document-delete-cancel:hover {
  color: var(--primary);
}

.close_btn {
  position: absolute !important;
  top: 10px;
  right: 10px;
  margin-left: 5px;
  z-index: 1 !important;
}

.folder_delete_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative !important;
}

.folder_select_container {
  width: 213px;
  height: 290px;
  opacity: 0 !important;
  position: absolute !important;
  transform: rotate(-90.04deg);
  flex-shrink: 0;
  background-color: #FFF !important;
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, .18) !important;
  align-items: center;
}

.empty_folder {
  width: 100%;
  height: 100px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.drop_file {
  font-size: 16px;
  font-weight: 600;
  font-family: var(--primary-font-family);
}

.create_folder {
  font-size: 14px;
  font-weight: 400;
  font-family: var(--primary-font-family);
  margin-top: 10px;
}

.folder_back_btn {
  height: 42.59px !important;
  text-transform: capitalize !important;
  border-radius: 6px !important;
  font-family: var(--primary-font-family) !important;
  font-size: 14px;
}

.upload_text {
  justify-content: center;
  text-align: center;
}

.files_body {
  display: flex;
  text-align: center !important;
  padding: 5px 10px 16px 0px;
}

.files_name {
  font-size: 14px;
  margin-left: 5px;
  font-family: var(--primary-font-family);
  text-align: center !important;
}

.empty_folder_text {
  font-size: 14px;
  font-family: var(--primary-font-family);
  text-align: center !important;
}

.folder_dot_img {
  position: relative;
}

.folder_dot_img .css-n1hybe-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  width: 10px !important;
}

.move_folder_name {
  font-size: 14px;
  font-family: var(--primary-font-family);
  color: var(--table-font-color);
  font-weight: 600;
  padding-bottom: 20px !important;
}

.move_folder_name span {
  margin-left: 5px;
  color: var(--primary);
}

.folder_move {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 16px;
  background: #ffffffff;
  border-radius: 4px;
  color: #1e2022ff;
  font-size: 12px;
  align-items: center;
  width: 130px;
  height: 48px;
  border: .0625rem solid rgba(231, 234, 243, .7);
  box-shadow: 0 0.375rem 0.75rem rgba(140, 152, 164, 0.075);
  user-select: none !important;
}

.folder_move:hover {
  cursor: pointer;
  box-shadow: var(--box-shadow);
}

.file_type_img {
  height: 20px;
  width: 20px;
  vertical-align: middle;
  border-radius: 5px 5px 0 0;
}

.folder_file_container {
  display: flex;
  align-items: center;
}

.folder_thumnail_name {
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: var(--primary-font-family) !important;
  color: #193037 !important;
  margin-left: 10px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.folder_file_name {
  font-size: 13px !important;
  font-weight: 500 !important;
  font-family: var(--primary-font-family) !important;
  color: #193037 !important;
  overflow: hidden !important;
  display: block !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.nav_folder_name {
  font-size: 14px !important;
  font-family: var(--primary-font-family) !important;
  border-radius: 5px;
}

.nav_folder_name span {
  margin-left: 10px;
}

.nav_folder_body {
  display: flex;
  align-items: center;
}

.nav_folder_wrap {
  display: flex;
  padding: 10px 0px 20px 0px;
  gap: 15px;
  margin-left: 1%;
}

.file-upsec-area {
  width: 100%;
  height: calc(100vh - 230px);
  border: 1px dashed var(--primary);
  border-radius: 5px;
  background: #f8f7ff;
  margin-bottom: 20px;
  position: relative;
}

.file-upsec-areas {
  width: 100%;
  height: calc(100vh - 230px);
  border: 1px dashed #0055f3;
  border-radius: 5px;
  background: #e7e3ff;
  margin-bottom: 20px;
  position: relative;
}