.inn-sec {
    padding: 30px 0 0px;
}

.wrapper {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    height: 100%;
    padding: 0 30px;
}

.c-header-recipient {
    text-align: left;
    border-bottom: 1px solid var(--border-color);
    padding: 5px 0;
    margin-bottom: 25px;
}

.title {
    display: inline-block;
}

.table {
    float: right !important;
}

.send-fill-img {
    width: 17px;
    height: 17px;
    flex-shrink: 0;
}

.cancel {
    color: var(--table-header-font) !important;
    text-align: center;
    text-transform: capitalize !important;
    display: inline-block;
    width: 85px;
    height: 42.59px;
    border: 1px solid var(--border-color) !important;
    margin-right: 10px !important;
    padding: 8px 0;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
}

.cancel:hover {
    color: var(--primary) !important;
}

.edit {
    text-transform: capitalize !important;
    text-align: center;
    display: inline-block;
    width: 85px;
    height: 42.59px;
    margin-left: 20px;
    padding: 8px 0;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
}

.send_approval {
    text-transform: capitalize !important;
    text-align: center;
    display: inline-block;
    width: auto !important;
    height: 42.59px;
    margin-left: 20px;
    padding: 8px 0;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
}

.reports-table {
    box-shadow: 0 6px 12px rgba(140, 152, 164, 0.08);
    border: 1px solid #cbd0e06e;
    border-radius: 12px;
    background: #fff;
    overflow: hidden;
    margin-top: 75px;
    max-height: calc(100vh - 262px);
}

.cont-data {
    max-height: calc(100vh - 262px);
    text-align: left;
    position: relative;
}

.cont-data::-webkit-scrollbar-track {
    background-color: inherit;
}

.cont-data::-webkit-scrollbar {
    max-height: 12px;
    max-width: 5px;
    min-height: 8px;
    min-width: 5px;
    background-color: #ffffff;
}

.cont-data::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px #0000004d;
    background: #b8b8b8;
    border-radius: 5px;
    width: 5px;
    height: 5px;
}

.head {
    border-top: unset;
    background-color: #f8fafd;
    border-color: var(--border-color);
    border-bottom: 0.0625rem solid var(--border-color);
}

.document-text {
    color: var(--table-header-font);
    font-weight: 600;
    opacity: 100%;
    text-align: left;
    padding: 15px;
}

.signtype-container {
    border-radius: 8px !important;
    background: #FFF !important;
    margin-top: 5px !important;
    box-shadow: var(--box-shadow) !important;
}

.left-text {
    width: 200px;
    font-weight: 500;
    box-sizing: border-box;
    font-weight: 400;
    color: var(--table-font-color);
    font-size: 14px;
}

.recipient-info {
    padding: 15px;
    position: relative;
    display: flex;
    align-items: center;
}

.signed-wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 80%;
}

.signed-container {
    padding: 15px;
    display: flex;
    align-items: baseline;
}

.select-recipient-container {
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: 10px;
}

.reminder-days {
    width: 20%;
    border: 1px solid #e7eaf3;
    border-radius: 5px;
    resize: vertical;
    padding: 10px;
}

.reminder-days-email {
    width: 70%;
    border: 1px solid #e7eaf3;
    border-radius: 5px;
    resize: vertical;
    padding: 10px;
}

.error-reminder-days {
    width: 80%;
    border: 1px solid rgb(227, 63, 72);
    border-radius: 5px;
    resize: vertical;
    padding: 10px;
}

.reminder-name {
    width: 50%;
    border: 1px solid #e7eaf3;
    border-radius: 5px;
    resize: vertical;
    padding: 10px;
    outline-color: var(--primary);
}

.css-jsfros-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    font-family: var(--primary-font-family) !important;
}
.reminder-name:focus {
    width: 50%;
    border: 1px solid var(--primary);
    border-radius: 5px;
    resize: vertical;
    padding: 10px;
    outline: none;
}

.error-reminder-name {
    width: 100%;
    border: 1px solid rgb(227, 63, 72);
    border-radius: 5px;
    resize: vertical;
    padding: 10px;
    margin-left: 10px;
}

.reminder-days:focus {
    width: 20%;
    outline: 0;
    border: 1px solid var(--primary);
    border-radius: 5px;
    resize: vertical;
    padding: 10px;
}

.reminder-days-email:focus {
    width: 70%;
    outline: 0;
    border: 1px solid var(--primary);
    border-radius: 5px;
    resize: vertical;
    padding: 10px;
}

@media only screen and (max-width: 1000px) {
    .signed-container {
        padding: 15px;
        display: flex;
        align-items: baseline;
    }

    .reminder-days {
        width: 20%;
        border: 1px solid #e7eaf3;
        border-radius: 5px;
        resize: vertical;
        padding: 10px;
        margin-top: 7px;
    }

    .reminder-name {
        width: 100%;
        border: 1px solid #e7eaf3;
        border-radius: 5px;
        resize: vertical;
        padding: 10px;
    }
}

@media only screen and (max-width: 800px) {
    .select-recipient-container {
        display: inline-block;
        padding: 15px;
        margin-left: 10px;
        width: 100%;
    }
}

.description-container {
    padding: 15px;
    display: flex;
    margin-bottom: 15px;
    align-items: center;
}

.sign-select {
    width: 100%;
    border: 1px solid var(--border-color) !important;
    color: var(--table-font-color) !important;
    border-radius: 5px;
    font-size: 13px !important;
    cursor: pointer;
    resize: vertical;
    max-width: 200px;
    outline: 0;
}

.add-text {
    color: #23ab94;
    margin-left: 20px;
    cursor: pointer;
    font-size: 14px;
}

.remove-text {
    color: #ff6868;
    margin-left: 20px;
    font-size: 14px;
}

.description {
    width: 80%;
    height: 90px;
    border: 1px solid #e7eaf3;
    border-radius: 5px;
    resize: vertical;
    padding: 10px;
    font-family: var(--primary-font-family);
}

.description:focus {
    width: 80%;
    border: 1px solid var(--primary);
    border-radius: 5px;
    resize: vertical;
    padding: 10px;
    outline: 0;
}

.react-switch-handle {
    height: 19px !important;
    width: 19px !important;
}

.css-1bn53lx {
    opacity: 0;
}

.css-nkbls5 div {
    opacity: 0;
}

.modal_result {
    position: absolute !important;
    max-height: 300px !important;
    width: 42% !important;
    border-radius: 5px !important;
    box-shadow: 0 0.6125rem 2.5rem 0.6125rem #8c98a42e !important;
    overflow: auto !important;
    background-color: #fff !important;
    margin-top: 38px !important;
    border: 1px solid #e7eaf3;
}

.modal_result::-webkit-scrollbar-track {
    background-color: inherit !important;
}

.modal_result::-webkit-scrollbar {
    max-height: 12px !important;
    max-width: 5px !important;
    min-height: 8px !important;
    min-width: 5px !important;
    background-color: #ffffff !important;
}

.modal_result::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px #0000004d !important;
    background: #b8b8b8 !important;
    border-radius: 5px !important;
    width: 5px !important;
    height: 5px !important;
}

.dropDown_list {
    text-align: left !important;
    padding: 12px 12px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #193037 !important;
}

.dropDown_list:hover {
    background-color: rgba(189, 197, 209, .2) !important;
}

.recipient_back_button {
    color: var(--primary) !important;
    border: 1px solid var(--primary) !important;
    margin-left: 0 !important;
    margin-right: auto !important;
}

.recipient_contact_back {
    width: 15px !important;
    height: 15px !important;
    margin-right: 5px !important;
}