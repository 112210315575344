.finish_btn{
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px 10px 10px;
    border-bottom: 1px solid rgb(232, 232, 232);
}
.pdf_all_files{
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 40px 8px;
    height: 100%;
    padding: 10px 24px;
}
.pdf_file{
    overflow-anchor: none;
    display: flex;
    flex-direction: row;
    gap: 8px;
}
.pdf_file_view{
    position: relative;
    display: flex;
    /* flex-wrap: wrap; */
    border: 1px solid transparent;
    user-select: none;
}
.pdf_add_next{
    cursor: pointer;
    height: 100%;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}
.pdf_template{
    width: 190px;
}
.pdf_template_view{
    border: 1px solid transparent;
    border-radius: 4px;
    position: relative;
    display: flex;
    flex-direction: column;
}
.pdf_template_image{
    position: relative;
    z-index: 0;
}
.pdf_image_view{
    width: 100%;
    aspect-ratio: 0.704225 / 1;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    touch-action: manipulation;
}
.pdf_template_name{
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    padding: 8px 12px;

}
.pdf_name_view{
    font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 0px;
    padding: 0px 8px;
    border-radius: 4px;
    max-width: 100%;
    color: rgb(102, 73, 0);
    background-color: rgba(255, 183, 0, 0.3);
}
.pdf_template_tools{
    box-shadow: rgba(26, 26, 26, 0.2) 0px 0px 4px;
    background: white;
    height: 28px;
    position: absolute;
    top: 2px;
    right: 2px;
    opacity: 0;
    display: flex;
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 4px;
    transition: opacity 0.2s ease 0s;
    z-index: 3;
}
.pdf_template_view:hover .pdf_template_tools{
    opacity: 1;
}
.pdf_template_delete{
    width: 28px;
    height: 28px;
    background: white;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    color: rgb(26, 26, 26);
    touch-action: manipulation;
}
.add_new_pdf{
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    gap: 12px;
    padding-left: 32px;
    padding-right: 32px;
    border: 1px dashed rgb(102, 153, 255);
    border-radius: 4px;
    color: rgb(102, 153, 255);
    width: 160px;
    height: 268px;
    margin-left: 20px;
}
.add_pdf_icon{
    width: 24px;
    height: 24px;
}
.add_pdf_text{
    font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
}