.main_wrap {
    padding: 0px 30px 8px;
    height: calc(100vh - 51px);
}

.main_contact_wrap {
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid #dfdfdfa8;
    margin-top: 10px;
}

.main_contact {
    overflow: auto;
    height: calc(100vh - 224px);
}

.main_contact::-webkit-scrollbar-track {
    background-color: inherit;
}

.main_contact::-webkit-scrollbar {
    max-height: 12px;
    max-width: 5px;
    min-height: 8px;
    min-width: 5px;
    background-color: #ffffff;
}

.main_contact::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px #0000004d;
    background: #b8b8b8;
    border-radius: 5px;
    width: 5px;
    height: 5px;
}

.recipient_body {
    max-width: 400;
}

.recipient_body::-webkit-scrollbar-track {
    background-color: inherit;
}

.recipient_body::-webkit-scrollbar {
    max-height: 12px;
    max-width: 5px;
    min-height: 8px;
    min-width: 5px;
    background-color: #ffffff;
}

.recipient_body::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px #0000004d;
    background: #b8b8b8;
    border-radius: 5px;
    width: 5px;
    height: 5px;
}

.new_contact_wrap {
    width: 500px;
    position: absolute;
    translate: -50% -50%;
    top: 50%;
    left: 50%;
    background-color: var(--white);
    border-radius: 10px;
    padding: 40px;
}

.close {
    position: absolute !important;
    top: 8px;
    right: 8px;
}

.new_contact_field_wrap {
    padding: 13px 0;
}

.new_contact_field_wrap input:focus {
    background-color: transparent;
}

.add_btn {
    margin-top: 15px;
}

.name {
    min-width: 200px;
}

.edit {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    gap: 10px;
}

.edit button:first-child:hover {
    background-color: var(--primary);
    color: white !important;
}

.edit .approve_btn:hover {
    background-color: var(--primary);
    color: white !important;
}

.edit .download_btn:hover {
    background-color: #23ab94;
    color: white !important;
}

.edit .delete_btn:hover {
    background-color: #ed4c78 !important;
    color: white !important;
}

.DeleteIcon:hover {
    color: white;
}

.custom_dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.back_button {
    color: var(--primary) !important;
    border: 1px solid var(--primary) !important;
    width: 80px !important;
    margin-left: 0 !important;
    margin-right: auto !important;
}

.stepIcon {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    translate: 7px;
    background: var(--primary);
}

.top_text {
    width: 70%;
    margin: 20px 0px 0px 30px;
    font-size: 14px !important;
}

.bottom_text {
    width: 80%;
    margin-left: 30px;
}

.css-1sn4lm3-MuiTypography-root {
    font-size: 2rem !important;
}

.close_btn {
    right: 10px;
    margin-left: 5px;
    z-index: 1 !important;
}

.edit_btn {
    right: 10px;
    margin-left: 15px;
    z-index: 1 !important;
}

.document_rename_input {
    width: 100%;
    display: flex;
    align-items: center;
}

.detailed_info {
    color: var(--table-header-font);
    gap: 20px;
    font-weight: 600;
    padding: 15px;
    border-bottom: 1px solid var(--border-color);
    font-size: 14px;
    background-color: #f8fafd;
    border-radius: 10px 10px 0px 0px;
}

.detailed_wrapper {
    display: flex;
    flex-direction: column;
    padding: 15px 20px;
}

.detailed_body {
    display: flex;
    font-weight: 500;
    color: #193037;
    font-family: var(--primary-font-family);
}

.detail_wrapper {
    min-width: 200px;
    font-weight: 500;
    font-size: 14px;
    color: var(--table-header-font);
    font-family: var(--primary-font-family);
    display: flex;
    align-items: center;
}

.device_name {
    font-size: 13;
    font-family: var(--primary-font-family);
    color: var(--table-font-color);
}