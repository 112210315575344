.wrapper {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    height: 100%;
    padding: 0 40px;
}

.search_scroll {
    overflow-y: auto;
    width: 100%;
    max-height: calc(100vh - 370px);
    padding: 0px 10px 18px 10px;
}

.search_scroll::-webkit-scrollbar-track {
    background-color: inherit;
}

.search_scroll::-webkit-scrollbar {
    max-height: 12px;
    max-width: 5px;
    min-height: 8px;
    min-width: 5px;
    background-color: #ffffff;
}

.search_scroll::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px #0000004d;
    background: #b8b8b8;
    border-radius: 5px;
    width: 5px;
    height: 5px;
}

.c-header {
    display: flex;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: #cbd0e06e;
    padding: 5px 0;
    margin-bottom: 25px;
}

.upload_topbar {
    right: 0 !important;
    top: 0 !important;
}

.title {
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
    padding: 15px 0px;
    margin: 0;
    font-family: var(--primary-font-family);
    color: var(--header-color);
}

.upload-doc {
    padding: 30px 0;
}

.sdk-upload-doc {
    padding: 4px 0;
}

.up-type>span {
    width: 32px;
    height: 32px;
    display: inline-block;
    margin: 0 12px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0 0;
    vertical-align: middle;
    cursor: pointer;
    filter: grayscale(100%);
    opacity: 0.8;
}

.up-type {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    /* filter: grayscale(100%); */
}

.up-type>img:hover {
    filter: none !important;
}

.supported-formats {
    padding: 10px 0 10px;
    font-family: var(--primary-font-family);
}

.formats {
    color: var(--table-font-color);
    font-weight: 500;
    font-size: 14px;
    display: inline-block;
}

.formats-group {
    color: var(--table-header-font);
    font-weight: 400;
    font-size: 14px;
}

.upsec-area {
    width: 100%;
    height: 360px;
    border: 1px dashed var(--primary);
    border-radius: 5px;
    background: #f8f7ff;
    margin-bottom: 20px;
    position: relative;
}

.upsec-areas {
    width: 100%;
    height: 360px;
    border: 1px dashed #0055f3;
    border-radius: 5px;
    background: #e7e3ff;
    margin-bottom: 20px;
    position: relative;
}

.browse-inn-part {
    width: 100%;
    height: 100%;
}

.before-upload {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.upload-file-text {
    color: var(--table-font-color);
}

.before-upload>span {
    color: var(--table-font-color);
}

.upload-channel {
    color: var(--table-font-color);
    font-family: var(--primary-font-family);
}

.or {
    display: block;
    margin-bottom: 14px;
}

.btn {
    border-radius: 6px !important;
    text-transform: capitalize !important;
    display: inline-block;
    height: 42.49px !important;
    border: none;
    color: var(--white);
}

.upload_search {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    align-items: center;
    white-space: nowrap;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 0px 10px 0px 10px;

}

.upload_search_field {
    display: flex;
    justify-content: center;
}

.search_container {
    display: flex;
    align-items: center;
}

.search_img {
    position: relative;
    z-index: 1;
    margin-left: 10px;
}

.upload_wrapper {
    padding: 10px 0px 0px 0px;
}

.upload_container {
    background: rgb(250, 250, 253);
    border: 1px solid var(--border-color);
    height: 100%;
    border-radius: 6px;
}

.upload_search:hover {
    display: flex;
    gap: 15px;
    align-items: center;
    white-space: nowrap;
    background-color: transparent;
}

.upload_search_bar {
    max-width: 300px;
}

.upload_search_bar:focus {
    background-color: transparent;
}

.button_container {
    display: flex;
    float: inline-end;
    gap: 10px;
}

.file-name {
    cursor: pointer;
    font-family: var(--primary-font-family) !important;
    width: 200 !important;
    white-space: nowrap !important;
    font-size: 14px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    color: var(--table-font-color) !important;
    user-select: none !important;
}

.back_button {
    text-transform: capitalize !important;
    font-family: var(--primary-font-family) !important;
}

.select_button {
    text-transform: capitalize !important;
    font-family: var(--primary-font-family) !important;
}

.upload-sign>span {
    color: #fff;
    font-weight: 500;
}

.upload-inprogress {
    position: relative;
    transform: translate(0%, 70%);
}

.up-text {
    margin: 20px 0 10px;
    font-size: 16px;
    font-weight: 500;
    color: var(--table-font-color);
}

.up-hint {
    color: #b5b5b5 !important;
    font-weight: 400 !important;
    font-size: 14px !important;
}

.box_img {
    width: 89px;
    height: 137px;
    flex-shrink: 0;
}

.upload_back_button {
    display: flex;
    justify-content: flex-start;
    position: absolute;
}

.login_page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
    top: 50%;
}

.login_header {
    font-size: 36px !important;
    color: var(--table-font-color) !important;
    font-family: var(--primary-font-family) !important;
    margin-bottom: 20px !important;
    letter-spacing: 0.15px !important;
}

.login_button {
    padding: 10px 20px !important;
    border-radius: 6px !important;
    background-color: var(--primary);
    text-transform: capitalize !important;
    font-size: 14px !important;
    cursor: pointer;
}

.refresh_header {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding-bottom: 20px !important;
  }

  .centilio_sign {
    font-weight: 600 !important;
    font-size: 22px !important;
    margin-left: 10px !important;
    font-style: normal !important;
    font-family: var(--primary-font-family) !important;
    color: var(--header-color) !important;
    line-height: 30px !important;
    letter-spacing: 0.188px !important;
  }

  .reload_text {
    font-size: 16px !important;
    font-family: var(--primary-font-family) !important;
    color: var(--table-font-color) !important;
    display: flex !important;
    justify-content: center !important;
    font-weight: 400 !important;
  }
  
  .reload_button {
    display: flex !important;
    justify-content: center !important;
    padding-top: 20px !important;
    gap: 20px !important;
  }