.table_wrap th,
.table_wrap td {
    padding: 3.3px 0px 3.3px 6px;
}

.table_wrap_main *::-webkit-scrollbar {
    max-height: 3px;
    max-width: 5px;
    background-color: #ffffff;
}

.select_pagination {
    box-shadow: var(--box-shadow) !important;
}

.pagination_item {
    display: flex !important;
    justify-content: center !important;
    font-family: var(--primary-font-family) !important;
}

.search_bar {
    max-width: 300px;
}

.search_bar:focus {
    background-color: transparent;
}

.table_nav {
    display: flex;
    justify-content: space-between;
    /* padding: 15px 0; */
    min-height: 90px;
    gap: 20px;
    align-items: center;
    flex-wrap: wrap;
}

.nav_item {
    display: flex;
    gap: 15px;
    align-items: center;
    white-space: nowrap;
}

.nav_item:hover {
    display: flex;
    gap: 15px;
    align-items: center;
    white-space: nowrap;
    background-color: transparent;
}

.table_container::-webkit-scrollbar-track {
    background-color: inherit;
}

.table_container::-webkit-scrollbar {
    max-height: 3px;
    max-width: 5px;
    min-height: 3px;
    min-width: 5px;
    background-color: #ffffff;
}

.table_container::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px #0000004d;
    background: #b8b8b8;
    border-radius: 5px;
    width: 5px;
    height: 5px;
}

.table_container_no_data {
    min-height: calc(100vh - 238px);
}

.table_container {
    position: relative;
    border-radius: 12px !important;
    /* max-height:100%; */
    overflow: auto;
    max-height: calc(100vh - 245px);
}


.table_container td {
    border: unset !important;
}

.table_container_wrap {
    height: auto;
    /* max-height: calc(100vh - 279px); */
    border: 1px solid var(--border-color);
    box-shadow: rgba(140, 152, 164, 0.075) 0px 0.375rem 0.75rem !important;
    border-radius: 12px;
    overflow: hidden;
}

.table_container_wrap table {
    border: unset;
}

.table_container_height {
    /* height: calc(100vh - 333px); */
    border: 1px solid var(--border-color);
    box-shadow: rgba(140, 152, 164, 0.075) 0px 0.375rem 0.75rem !important;
    border-radius: 12px;
    overflow: hidden;
}

.table_container_height table {
    border: unset;
}

.model_popup_form {
    display: flex;
    padding-top: 10px;
    gap: 30px;
    justify-content: space-around;
}

.model_popup_head {
    padding-top: 5px;
    width: 100px;
}

.model_popup_textarea {
    height: 80px !important;
    overflow: hidden !important;
    width: 478px !important;
    resize: none !important;
    border-radius: 12px 12px 12px 12px !important;
}

.model_popup_input {
    /* height: 80px !important; */
    overflow: hidden !important;
    width: 478px !important;
    resize: none !important;
    border-radius: 12px 12px 12px 12px !important;
}

.table_container_wrap tr>*:first-child {
    position: sticky;
    left: 0;
    width: 1rem;
    text-align: center;
}

.table_container_height tr>*:first-child {
    position: sticky;
    left: 0;
    width: 1rem;
    text-align: center;
}

.table_head {}

.table_head_val tr>*:first-child {
    z-index: 100;
}

.table_container_wrap tr>*:first-child::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #f8fafd;
    z-index: -1;
}

.table_container_height tr>*:first-child::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #f8fafd;
    z-index: -1;
}

.clear_icon {
    display: none !important;
}

.input_filed:hover .clear_icon {
    display: block !important;
}

.filter_filed {
    display: flex;
    gap: 5px;
    border: 1px solid var(--border-color);
    border-radius: 0.3125rem;
    padding: 8px 16px;
    align-items: center;
    color: var(--table-header-font);
}

.filter_leanth {
    display: flex;
    gap: 5px;
    border: 1px solid var(--border-color);
    border-radius: 0.3125rem;
    padding: 8px 16px;
    align-items: center;
    color: var(--primary);
    background-color: aliceblue;
    justify-content: end;
}
.filter_leanth:hover {
    /* background-color: #e3dffc; */
}

.filter_clear {
    display: none;
    position: absolute;
    background: aliceblue;

}

.filter_leanth:hover .filter_clear{
    display: flex;
    color: #bdbdbd
}

.filter_clear :hover {
    display: flex;
    color:var(--primary);
}

.filter_filed:hover {
    box-shadow: rgba(140, 152, 164, 0.075) 0px 0.375rem 0.75rem !important;
    color: var(--primary);
    background-color: #edecf2;
}

.filter_leanth:hover(.filter_clear){
    color: red;
}

@media (max-width: 888px) {
    .table_container {
        max-height: calc(100vh - 280px);
    }

    .table_container_no_data {
        min-height: calc(100vh - 280px);
    }
}

@media (max-width: 460px) {
    .table_container {
        max-height: calc(100vh - 300px);
    }

    .table_container_no_data {
        min-height: calc(100vh - 300px);
    }
}