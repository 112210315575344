.box_head {
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
    width: 90%;
    text-align: left;
    margin: 0 auto;
    max-width: 100%;
}

.plans {
    display: inline-block;
    width: 90%;
    margin: 0 1%;
    background: #f5f5f5;
    border-radius: 32px;
    padding: 38px 48px;
    vertical-align: top;
    min-height: 540px;
}

.header {
    color: var(--header-color);
    font-size: 22px;
}

.phara {
    font-size: 14px;
    color: var(--table-header-font);
}

.price {
    display: block;
    padding-bottom: 20px;
}

.price div {
    display: inline-block;
    vertical-align: middle;
}

.money h2 {
    font-size: 48px;
    line-height: 58px;
}

.plan_div {
    padding-left: 10px;
}

.plan_div p {
    font-family: var(--primary-font-family);
    margin-bottom: 0px;
    margin-top: 0px;
    font-size: 14px;
    line-height: 22px;
    color: var(--table-header-font);
}

.plans .pay_button {
    padding: 3% 34%;
    text-transform: capitalize;
}

.plans .price_lists {
    background-color: transparent;
}

.plans .list .list_button {
    background: none;
}

.plans .list .price_lists {
    color: var(--header-color);
    font-weight: 600;
}

.list_button .listed_text span {
    font-family: Inter;
    color: var(--table-header-font);
}

@media only screen and (max-width: 1180px) {
    .box_head {
        grid-template-columns: 100%;
    }

    .plans .pay_button {
        padding: 12px 64px;
    }
}

.check_icon {
    background-color: #ebebeb;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    margin-right: 10px;
}

.most_popular {
    color: #387cff;
    padding-bottom: 20px;
    text-transform: uppercase;
    font-size: 14px;
}