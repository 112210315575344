.wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;
    border-bottom: 1px solid var(--border-color);
    /* margin-bottom: 20px; */
}

.header_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;
    margin-bottom: 20px;
}

.btn {
    height: 42.59px !important;
    padding: 10.8px 17px !important;
    font-weight: 400 !important;
}

.header_text {
    color: var(--header-color) !important;
    font-weight: 600;
    font-size: 22px;
    font-family: var(--primary-font-family);
    line-height: 28px;
}

.upload_img {
    width: 16px;
    height: 16px;
    margin-right: 0.25rem !important;
}

.contact_img {
    width: 15px;
    height: 15px;
    /* margin-right: 0.25rem !important; */
}

.header_wrapper {
    display: flex !important;
    align-items: center !important;
}

.tooltip_text {
    font-size: 12px !important;
    margin: 10px;
}

.got_it {
    font-size: 12px !important;
    margin: 0px 10px 10px 10px;
    cursor: pointer !important;
    font-weight: 600 !important;
    padding: 5px;
    color: var(--table-font-color);
}

.got_it:hover {
    background-color: var(--primary);
    border-radius: 2px;
    color: #fff;
}

.app_header_profile {
    display: flex !important;
    align-items: center !important;
    margin-right: 10px !important;
}

.next_wrapper {
    display: flex;
    justify-content: space-between;
}

.upload_contact {
    background-color: var(--primary) !important;
    color: #fff !important;
    height: 42.59px !important;
    font-size: 14px !important;
    border-radius: 0px 6px 6px 0px !important;
    margin-left: -6px;
    box-shadow: 2px 2px 1px -2px rgba(0, 0, 0, 0.2), 2px 1px 2px 1px rgba(0, 0, 0, 0.14), 1px 1px 1px 0px rgba(0, 0, 0, 0.12);
}

.upload_contact:hover {
    background-color: rgb(38, 87, 178) !important;
}

.file_upload_wrapper {
    font-size: 13px !important;
    font-weight: 400 !important;
    width: 90% !important;
    border-radius: 6px !important;
    display: flex !important;
    margin-left: 9px !important;
}

.file_upload_svg {
    width: 17px;
    height: 17px;
    margin-right: 7px;
}