.top_bar{
    position: relative;
    z-index: 10;
    background-color: white;
    padding-top: 24px;
}
.top_bar_main{
    padding: 0px 75px;
    display: flex;
    flex-wrap: nowrap;
    height: 56px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}
.logo_text{
    padding-top: 5px;
    font-weight: 600;
    font-size: 20px;
    margin-left: 6px;
    font-style: normal;
    font-family: var(--primary-font-family);
    color: var(--header-color);
    line-height: 30px;
    letter-spacing: 0.188px;
}
.top_bar_img{
    display: flex;
    height: 74%;
    padding-right: 12px;
}
.top_bar_logo{
    display: flex;
    height: 100%;
    padding-right: 12px;
}
.logo_img{
    height: 40px;
    width: 40px;
}
.menu_bar{
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-flex: 1;
    flex-grow: 1;
    height: 100%;
}
.tools_menu{
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0px 18px;
    height: 100%;
}
.tools_padding{
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    transition: color 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s, background-color 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
    border-bottom: 2px solid rgb(0, 85, 255);
    gap: 4px;
    cursor: pointer;
    color: rgb(0, 85, 255);
}
.head_flex{
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow: hidden;
    margin: 0px;
    padding: 0px;
    height: 100%;
}
.head_top{
    display: flex;
    height: 100%;
}

.head_names{
    align-items: center;
    font-family:  sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgb(33, 33, 33);
    white-space: nowrap;
    height: 100%;
    padding: 14px 12px;
    cursor: pointer;
    transition: color 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
}
.head_button{
    padding: 8px 12px;
}
.head_names:hover{
    color: rgb(152, 152, 152);
}
.head_names:active{
    color: rgb(202, 202, 202);
}
.meditation_div{
    text-align: center;
    padding-top: 90px;
}
.meditation_head{
    font-weight: bold;
    font-size: 62px;
    margin-bottom: 16px;
}
.meditation_des{
    font-size: 20px;
    line-height: 28px;
    margin: 0 auto;
    padding-left: 22px;
    padding-right: 22px;
    text-align: left;
    width: 50%;
}