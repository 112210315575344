.new_limit_wrap {
    width: 500px;
    position: absolute;
    translate: -50% -50%;
    top: 50%;
    left: 50%;
    background-color: var(--white);
    border-radius: 10px;
    padding: 20px;
}

.textfield {
    display: flex !important;
    justify-content: space-between !important;
    gap: 5px;
    width: 100%;
    padding: 30px 0px 10px 0px;
    font-family: var(--primary-font-family);
    font-size: 16px;
    line-height: 25px;
    color: var(--table-font-color);
}

.close {
    position: absolute !important;
    top: 8px;
    right: 8px;
}