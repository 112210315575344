.wrapper {
    width: 100%;
    margin: 0 auto;
    height: 100%;
    padding: 0 30px;
}

.header_text {
    font-size: 22px !important;
    font-weight: 600 !important;
    margin: 0 !important;
    padding: 30px 0 10px !important;
    color: var(--header-color) !important;
}

.scroll_y>div {
    display: block;
    margin-bottom: 40px;
    box-shadow: 0 0.375rem 0.75rem #8c98a414;
    border-radius: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--border-color);
}

.scroll_y>div .b_cont .wrapper {
    padding: 25px 22px 10px;
    background: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    text-align: left;
}

.sign_wrapper {
    padding: 10px;
    background: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    text-align: left;
}

.scroll_y>div .b_cont .wrapper .row {
    margin-bottom: 25px;
    display: table;
    table-layout: fixed;
    width: 100%;
}

.scroll_y>div h3 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    padding: 15px 22px;
    text-align: left;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: #cbd0e06e;
    color: var(--header-color) !important;
    background: #fff;
}

.new_contact_wrap {
    width: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 10px;
    padding: 40px;
}

.sign_frame {
    width: 40%;
    height: 130px;
    border: 1.5px dashed #dcdcdc;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
}

.scroll_y .default_sign .sign_btn {
    vertical-align: bottom !important;
}

.btn {
    padding: 14px 25px !important;
    border-radius: 6px;
    background-color: var(--primary);
    display: inline-block;
    cursor: pointer;
    border: none;
}

.btn span {
    color: #fff;
    font-weight: 500;
}

.signature {
    width: 550px;
    padding: 30px;
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    z-index: 3;
    border-radius: 10px;
}

.pop_close {
    display: block;
    width: 35px;
    height: 0px;
    background: var(--black);
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
    cursor: pointer;
    opacity: 0.5;
    position: absolute;
    right: 10px;
    top: 5px;
    z-index: 1;
}

.popup_title {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.popup_title span {
    font-size: 22px !important;
    font-weight: 600 !important;
    margin: 0 !important;
    padding: 30px 0 10px !important;
    color: var(--header-color) !important;
}

.link:hover,
.link:focus,
.link:active {
    color: #000000;
    text-decoration: none;
}

.popup_cont {
    padding-top: 20px;
}

.s_menu_type {
    margin-bottom: 15px;
}

.s_menu_type span {
    cursor: pointer;
    padding-bottom: 5px;
    display: inline-block;
    margin-right: 20px;
    font-weight: 500;
    font-size: 15px;
}

.sign_area {
    height: 230px;
    border-radius: 5px;
    border: 1px dashed var(--primary);
}

.top_bar {
    padding: 20px;
    text-align: left;
    background: #f8f7ff;

    border-radius: 5px;
}

.sign_upload_img {
    display: flex;
    justify-content: center;
}

.select_pen {
    display: inline-flex;
    gap: 10px;
    width: 68%;
}

.select_pen span {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
    user-select: none;
}

.select_pen span.black {
    background: #000;
}

.select_pen span.blue {
    background: #1e87ca;
}

.select_pen span.red {
    background: #ac3235;
}

.select_pen span.green {
    background: #108473;
}

.clear_pad {
    display: inline-block;
    float: right;
    user-select: none;
}

.clear_pad span {
    cursor: pointer;
    color: #101316;
}

.font_sign {
    /* position: absolute; */
    bottom: 50px;
    width: 100%;
    top: 100px;
    margin-left: 45px;
    margin-top: 35px;
}

.font_sign input {
    border: none;
    width: 100%;
    height: 100%;
    font-size: 50px;
    background: transparent;
    padding: 0px 96px 0px 0px;
    text-align: center;
}

.font_sign input:focus {
    outline: none;
    background-color: #ffffff00;
}

.upload-area {
    width: 100%;
    height: 100%;
}

.inn_part {
    position: absolute;
    top: 31%;
}

.drop_file_text {
    font-weight: 500;
    color: var(--black);
    font-size: 16px;
    margin-bottom: 10px;
}

.upload_area .or {
    display: block;
    text-align: center;
    margin-bottom: 14px;
}

.upload_sign {
    padding: 8px 18px 10px;
}

.upload_sign:hover {
   background-color: rgb(38, 87, 178);
   box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}

.btn {
    height: 42.59px !important;
    text-transform: capitalize !important;
    border-radius: 6px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer !important;
    color: #fff !important;
    font-weight: 500 !important;
    font-family: var(--primary-font-family) !important;
    font-size: 14px !important;
}

.select_font {
    display: flex;
    float: right;
    margin-top: -10px;
}

.select {
    border: none;
    background: transparent;
    outline: none;
    border-bottom: 1px solid #cdcdd0;
}

.sign_terms {
    padding: 20px 0 30px;
    text-align: justify;
}

.submit {
    text-transform: capitalize !important;
    height: 42.59px;
    color: #fff !important;
    font-family: var(--primary-font-family) !important;
    float: right !important;
    position: initial !important;
}

.cancel {
    text-transform: capitalize !important;
    border-radius: 6px !important;
    display: inline-block;
    background: #fff;
    border: 1px solid var(--border-color) !important;
    width: unset !important;
    height: 42.59px;
    line-height: 12px;
    color: var(--table-header-font) !important;
    font-family: var(--primary-font-family) !important;
    position: initial !important;
}

.cancel:hover {
    color: var(--primary) !important;
}
.cropper_cancel {
    border: none !important;
    text-transform: capitalize !important;
    border-radius: 5px !important;
    display: inline-block;
    width: unset !important;
    height: 42.59px;
    line-height: 12px !important;
    color: #FFFFFF !important;
    cursor: pointer !important;

}

.font_color {
    border: 1px solid #FFFFFF;
    border-width: thin;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: relative;
    align-items: center;
    padding: 3px 0px 3px 3px
}

.active_font_color {
    border: 1px solid #adadad;
    border-width: thin;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: relative;
    align-items: center;
    padding: 3px 0px 0px 3px
}

.save_btn {
    color: #fff;
    background-color: var(--primary);
}

.cropper_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 360px;
    background-color: #d4e9ff;
    border-radius: 10px;
    padding: 30px;
    overflow: hidden;
}

.signature_img {
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    color: #193037;
    font-weight: 400 !important;
    font-size: 14px !important;
}

.img_container {
    top: 50% !important;
}

.signature_checkbox {
    display: flex;
    align-items: center;
    color: var(--table-font-color);
    font-size: 14px;
    width: 150px;
    max-width: 300px;
}

.button_container {
    margin-left: auto;
    margin-top: auto;
    padding: 20px;
}

.sign_save_button {
    height: 42.59px !important;
    text-transform: capitalize !important;
    border-radius: 6px !important;
    font-family: var(--primary-font-family) !important;
    font-size: 14px;
}
.signature_type_wrapper {
    width: fit-content !important;
    translate: 2000px !important;
    display: flex !important;
    align-items: center !important;
    min-height: 50px;
    font-size: 50px !important;
}