.more_icon_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: relative;
}

.more_icon_button:hover {
    background-color: #ffffff14;
}

.select_more_options li {
    max-height: 53px;
    min-height: 40px;
}

.select_more_options {
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, .18) !important;
}

.more_condition_icon_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: relative;
}

.select_condition_more_options {
    translate: -30px -17px;
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, .18) !important;
}

.select_condition_more_options ul {
    padding: 0;
}

.select_condition_more_options_type {
    translate: 5px -27px;
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, .18) !important;
}

.select_condition_more_options_type ul {
    padding: 0;
}