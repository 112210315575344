.sign-preview-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 198px);
    background-color: #fff !important;
}

.sign-pdf-preview-container {
    position: relative;
    margin: 20px 0 20px 0;
}

.sign-preview-wrapper::-webkit-scrollbar-track {
    background-color: inherit;
}

.sign-preview-wrapper::-webkit-scrollbar {
    max-height: 5px !important;
    max-width: 5px !important;
    min-height: 8px !important;
    min-width: 5px !important;
    background-color: #ffffff !important;
}

.sign-preview-wrapper::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px #0000004d;
    background: #b8b8b8;
    border-radius: 5px;
    width: 3px !important;
    height: 3px !important;
}

.sign-leftbar-container {
    line-height: 10px !important;
}

.sign-pdf-viewer-preview {
    height: 125px !important;
    width: 102px !important;
    border: 1.5px solid #e4e4e4;
    background: #FFF;
    box-shadow: 0 0 4px 2px #4b3cab33;
    border-radius: 10px;
    cursor: pointer;
}

.sign-pdf-select-preview {
    height: 125px !important;
    width: 102px !important;
    border: 1.5px solid var(--primary) !important;
    background: #FFF !important;
    box-shadow: 0 0 4px 2px #4b3cab33;
    border-radius: 10px;
    cursor: pointer;
}

.sign-preview-body {
    display: flex;
    text-align: center;
    justify-content: center;
    height: 100px;
    border-bottom: 1px solid rgba(231, 234, 243, 0.70);
}

.sign-preview-body .react-pdf__Page__canvas {
    max-height: 97px !important;
}

.sign-page-number {
    color: #111;
    font-weight: 500;
    font-size: 14px;
    width: 99px;
    height: 22px;
    display: flex;
    justify-content: center;
    border-radius: 0 0 10px 10px;
    background-color: #F8FAFD;
}

.sign-pdf-preview-img {
    display: flex;
    text-align: center;
}

.react-pdf__Page {
    margin-top: 2px;
}

.sign-tag-container {
    height: 70% !important;
    float: right !important;
    position: absolute !important;
    right: 0 !important;
    top: 4px !important;
}

.sign-tag-body {
    width: 26px;
    height: 8px;
    margin-top: 1px;
}