.completed circle {
    color: #ecf2ff;
}

.completed text {
    fill: #377dff;
}

.completed .lable_value span {
    color: #377dff;
}

.active circle {
    color: #ecf2ff;
}

.active text {
    fill: #377dff;
}

.active .lable_value span {
    color: #377dff;
}

.top_grid {
    width: 100%;
    height: 100%;
    overflow: scroll;
    position: absolute;
    padding: 0px 50px 50px 50px;
}

.top_grid .top_stepper {
    margin-bottom: 30px;
}

.top_grid::-webkit-scrollbar {
    width: 0;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
.top_grid::-webkit-scrollbar-thumb {
    background: #FF0000;
}