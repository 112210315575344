.user_info {
  border: 1px solid var(--border-color);
  border-radius: 12px;
  margin-bottom: 1rem;
  background: rgb(248 250 253);
  width: 25rem;
}

.grid_container {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 70px;
  padding: 10px;
  background: rgb(248 250 253);
  justify-content: start;
}

.grid_item {
  padding: 5px;
  font-family: inter;
  color: var(--font);
  font-size: 14px;
}

.table_name {
  padding: 10px 20px;
  color: var( --primary)
}

.avatar_name_email {
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 10px;
  border-bottom: 1px solid var(--border-color);
}

.first_name{
  font-size: 16px;
}
.email_id{
  font-size: 14px;
}
.table_head_name{
  padding:10px 0px;
  color: var( --primary);
  font-size: 16px;
  
}

/* .header {
  font-weight: bold;
  background-color: #e9e9e9;
  border-bottom: 1px solid #ccc;
} */