.main_contact_wrap {
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid #dfdfdfa8;
    margin-top: 20px;
}

.main_contact {
    overflow-y: auto;
    height: calc(100vh - 224px);
}

.main_contact::-webkit-scrollbar-track {
    background-color: inherit;
}

.main_contact::-webkit-scrollbar {
    max-height: 12px;
    max-width: 5px;
    min-height: 8px;
    min-width: 5px;
    background-color: #ffffff;
}

.main_contact::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px #0000004d;
    background: #b8b8b8;
    border-radius: 5px;
    width: 5px;
    height: 5px;
}

.new_contact {
    color: var(--header-color) !important;
    font-size: 24px !important;
    font-weight: 600;
    margin: 0;
    text-align: left;
    font-size: Inter !important;
}

.name_textfield {
    display: flex !important;
    justify-content: space-between !important;
    gap: 5px;
    width: 100%;
    padding-top: 10px;
}

.new_contact_wrap {
    width: 500px;
    position: absolute;
    translate: -50% -50%;
    top: 50%;
    left: 50%;
    background-color: var(--white);
    border-radius: 10px;
    padding: 40px;
}

.close {
    position: absolute !important;
    top: 8px;
    right: 8px;
}

.new_contact_field_wrap {
    padding: 13px 0;
}

.new_contact_field_wrap input:focus {
    background-color: transparent;
}

.add_btn {
    padding: 14px 25px !important;
    margin-top: 10px !important;
    text-transform: unset !important;
}

.name {
    min-width: 200px;
    font-size: 14px;
    font-weight: 500;
    color: var(--table-header-font);
}

.edit {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 10px;
}

.edit button:first-child:hover {
    background-color: var(--primary);
    color: white !important;
}

.edit button:nth-child(2):hover {
    background-color: #23ab94;
    color: white !important;
}

.edit button:nth-child(3):hover {
    background-color: #ff6868;
    color: white !important;
}

/* // color: ' #23AB94',
//  color:'#FF6868' */

.DeleteIcon:hover {
    color: white;
}

.contact_right_text {
    font-size: 14px;
    font-weight: 400;
    color: var(--table-font-color);
}

.contact_header {
    color: var(--table-header-font);
    gap: 20px;
    font-weight: 600;
    padding: 15px 20px;
    border-bottom: 1px solid #dfdfdfa8;
    font-size: 14px;
    background-color: #f8fafd;
    border-radius: 10px 10px 0px 0px;
}

.left_container {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 15px 20px;
}

.left_body {
    display: flex;
    font-weight: 400;
}

.contact_back {
    width: 15px !important;
    height: 15px !important;
    margin-right: 5px !important;
}

.contact_delete {
    color: var(--danger) !important;
    border: 1px solid var(--danger) !important;
    text-transform: unset !important;
}

.contact_delete:hover {
    color: #ffffff !important;
    background-color: var(--danger) !important;
    border: 1px solid var(--danger) !important;
    text-transform: unset !important;
}

.back_button {
    color: var(--primary) !important;
    border: 1px solid var(--primary) !important;
    margin-left: 0 !important;
    margin-right: auto !important;
}

.back_button:hover {
    color: #ffffff !important;
    background-color: var(--primary) !important;
}

.edit_button {
    color: #23AB94 !important;
    border: 1px solid #23ab94 !important;
    text-transform: unset !important;
    width: 80px !important;
}