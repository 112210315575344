.container_height{
    overflow: scroll;
    height: 100%;
    position: absolute;
    width: 100%;
    padding-bottom: 113px;
}
.container_height img{
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
}

.container_height::-webkit-scrollbar {
    width: 0;
    background: transparent;
}
.container_height::-webkit-scrollbar-thumb {
    background: #FF0000;
}
.container{
    padding-top: 20px;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}
.center_div{
    box-shadow: 0 0.125rem 1.25rem 0 rgb(153 155 168 / 12%);
    border: 6px solid #F3F3F3;
    border-radius: 10px;
    padding: 65px;
    width: 1000px;
}
.center_div strong{
    font-weight: 700;
}
.center_div p{
    font-family: Roboto, sans-serif;
}
.image_20{
    max-width: 28% !important;
    margin-bottom: 40px;
}
.subheading {
    width: 800px;
    color: #000;
    margin-bottom: 20px;
    font-size: 26px;
    line-height: 44px;
}
.image_19{
    margin-bottom: 80px;
}
.how_to_use{
    color: #0085ff;
    margin-bottom: 50px;
    font-size: 36px;
    font-weight: 700;
}
.step_headline{
    margin-bottom: 16px;
    font-size: 26px;
    font-weight: 700;
}
.text_step{
    margin-bottom: 32px;
    font-size: 18px;
    font-weight: 400;
}
.steps{
    margin-bottom: 50px;
}
.step_headline{
    margin-bottom: 16px;
    font-size: 26px;
    font-weight: 700;
}
.image_18{
    float: none;
    margin-bottom: 80px;
    margin-left: 0;
    padding-left: 0;
}