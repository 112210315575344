.integration-container::-webkit-scrollbar-track {
    background-color: inherit;
}

.integration-container::-webkit-scrollbar {
    max-height: 12px;
    max-width: 5px;
    min-height: 8px;
    min-width: 5px;
    background-color: #ffffff;
}

.integration-container::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px #0000004d;
    background: #b8b8b8;
    border-radius: 5px;
    width: 5px;
    height: 5px;
}

.integration-container {
    width: 100%;
    height: 100%;
    padding: 0 30px 30px;
    align-items: center;
    overflow: auto;
    height: calc(100vh - 72px);
}

.integration-title {
    font-size: 22px !important;
    font-weight: 600 !important;
    margin: 0;
    font-family: var(--primary-font-family) !important;
    line-height: 28px !important;
    color: var(--header-color) !important;
}

.integration_body {
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid rgba(231, 234, 243, 0.7) !important;
    box-shadow: 0 0.375rem 0.75rem #8c98a414 !important;
    /* width: 29% !important; */
    max-width: 500px;
    min-width: 415px;
    display: inline-block;
    border-radius: 6px;
    transition: 0.3s;
    cursor: default !important;
    position: relative;
    margin: 2%;
}

.integration_body:hover {
    box-shadow: var(--box-shadow) !important;
    cursor: default !important;
}

.box-img {
    width: 50px;
    height: 50px;
}

.dropbox-img {
    width: 40px;
    height: 40px;
}

.integration_wrapper {
    width: 100%;
    margin-top: 20px;
}

.integration_box_body {
    width: 100%;
    min-width: 90%;
    display: flex;
    flex-wrap: wrap;
}

.integration_box {
    display: flex;
    height: 40px;
    justify-content: space-between;
    align-items: center;
}

.integration_box_connect {
    display: flex;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    opacity: 0.2;
}

.integration_text_header {
    font-size: 14px;
    font-weight: 500px;
}

.integration_text {
    margin-top: 20px;
}

.integration_text_connect {
    margin-top: 20px;
    opacity: 0.2;
}

.box-connect {
    border: 1px solid var(--border-color) !important;
    text-transform: capitalize !important;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    background-color: #ffffff;
    box-shadow: 0 0.375rem 0.75rem #8c98a414 !important;
    color: var(--table-font-color) !important;
}

.box-connected {
    border: none !important;
    text-transform: capitalize !important;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    background-color: #23ab94 !important;
    box-shadow: 0 0.375rem 0.75rem #8c98a414 !important;
    color: #fff !important;
}

.box-connect:hover {
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: var(--box-shadow) !important;
}


.card_body {
    padding: 20px;
}

.top_text {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: var(--table-font-color) !important;
    font-family: var(--primary-font-family) !important;
}

.bottom_text {
    font-size: 14px !important;
    font-weight: 400 !important;
    margin-top: 5px !important;
    color: var(--table-header-font) !important;
    font-family: var(--primary-font-family) !important;
}

.disconnect {
    position: absolute;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0 !important;
}

.disconnect_button {
    text-transform: capitalize !important;
    display: block !important;
}

.reconnect_button {
    text-transform: capitalize !important;
    border: 1px solid #23ab94 !important;
    color: #23ab94 !important;
}

.expired_button {
    text-transform: capitalize !important;
    color: #ed4c78 !important;
    border: 1px solid #ed4c78 !important;
    border-radius: 4px;
    font-size: 14px !important;
    font-weight: 400 !important;
}

.disconnect-modal {
    display: block;
    opacity: 1 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.reconnect {
    border: 1px solid var(--border-color) !important;
    text-transform: capitalize !important;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    background-color: #23ab94 !important;
    box-shadow: 0 0.375rem 0.75rem #8c98a414 !important;
    color: #fff !important;
}

.req_crm_container {
    width: 500px;
    position: absolute;
    translate: -50% -50%;
    top: 50%;
    left: 50%;
    background-color: var(--white);
    border-radius: 10px;
    padding: 20px;
}