.pdf-viewer-main {
    width: 100%;
    position: relative;
}

/* @media only screen and (max-width: 830px) {
    .pdf-viewer-main {
        display: none;
    }
} */

.pdf-previewer {
    width: 11%;
    display: block;
    border-right: 1px solid var(--border-color);
    flex-shrink: 0;
}

.pdf-fields-page {
    width: 24%;
    justify-content: center;
    flex-grow: 0;
    display: inline;
}

.pdf-document-container {
    width: 65%;
}

.pdf-document {
    width: 100%;
    height: calc(100vh - 70px);
    background-color: #F8FAFD;
    overflow: scroll;
}

.pdf-top-container {
    text-align: center;
    width: 90px;
    margin: 0 auto;
    border: 1px solid var(--border-color);
    border-radius: 20px;
    font-weight: 400;
    justify-content: center;
    background-color: #FFFFFF;
}

.document-page-input {
    width: 30px;
    padding: 6px 0px 6px 0px;
    text-align: center;
    font-size: 16px;
    border: none;
    background: none;
    font-weight: 500;
    outline: none;
}

.document-page-count {
    background-color: #F8FAFD;
    padding: 10px;
}

@media only screen and (max-width: 1190px) {
    .pdf-previewer {
        width: 11%;
        display: block;
        border-right: 1px solid var(--border-color);
    }

    .pdf-document-container {
        width: 60%;
    }

    .pdf-fields-page {
        width: 29%;
        flex-grow: 0;
        display: inline;
    }

    .pdf-document {
        width: 100%;
        height: calc(100vh - 100px);
        background-color: #F9F9FB;
        overflow: scroll;
    }
}

@media only screen and (max-width: 1120px) {
    .pdf-previewer {
        display: none;
    }

    .pdf-document-container {
        width: 70%;
    }

    .pdf-fields-page {
        width: 30%;
        flex-grow: 0;
        display: inline;
    }

    .pdf-document {
        width: 100%;
        height: calc(100vh - 100px);
        background-color: #F9F9FB;
        overflow: scroll;
    }
}

@media only screen and (max-width: 1060px) {
    .pdf-previewer {
        display: none;
    }

    .pdf-document-container {
        width: 68%;
    }

    .pdf-fields-page {
        width: 32%;
        flex-grow: 0;
        display: inline;
    }

    .pdf-document {
        width: 100%;
        height: calc(100vh - 100px);
        background-color: #F9F9FB;
        overflow: scroll;
    }
}

@media only screen and (max-width: 990px) {
    .pdf-previewer {
        display: none;
    }

    .pdf-document-container {
        width: 67%;
    }

    .pdf-fields-page {
        width: 33%;
        flex-grow: 0;
        display: inline;
    }

    .pdf-document {
        width: 100%;
        height: calc(100vh - 100px);
        background-color: #F9F9FB;
        overflow: scroll;
    }
}

@media only screen and (max-width: 960px) {
    .pdf-previewer {
        display: none;
    }

    .pdf-document-container {
        width: 66%;
    }

    .pdf-fields-page {
        width: 34%;
        flex-grow: 0;
        display: inline;
    }

    .pdf-document {
        width: 100%;
        height: calc(100vh - 100px);
        background-color: #F9F9FB;
        overflow: scroll;
    }
}

@media only screen and (max-width: 930px) {
    .pdf-previewer {
        display: none;
    }

    .pdf-document-container {
        width: 65%;
    }

    .pdf-fields-page {
        width: 35%;
        flex-grow: 0;
        display: inline;
    }

    .pdf-document {
        width: 100%;
        height: calc(100vh - 100px);
        background-color: #F9F9FB;
        overflow: scroll;
    }
}

@media only screen and (max-width: 905px) {
    .pdf-previewer {
        display: none;
    }

    .pdf-document-container {
        width: 64%;
    }

    .pdf-fields-page {
        width: 36%;
        flex-grow: 0;
        display: inline;
    }

    .pdf-document {
        width: 100%;
        height: calc(100vh - 100px);
        background-color: #F9F9FB;
        overflow: scroll;
    }
}

@media only screen and (max-width: 880px) {
    .pdf-previewer {
        display: none;
    }

    .pdf-document-container {
        width: 63%;
    }

    .pdf-fields-page {
        width: 37%;
        flex-grow: 0;
        display: inline;
    }

    .pdf-document {
        width: 100%;
        height: calc(100vh - 100px);
        background-color: #F9F9FB;
        overflow: scroll;
    }
}

@media only screen and (max-width: 855px) {
    .pdf-previewer {
        display: none;
    }

    .pdf-document-container {
        width: 62%;
    }

    .pdf-fields-page {
        width: 38%;
        flex-grow: 0;
        display: inline;
    }

    .pdf-document {
        width: 100%;
        height: calc(100vh - 100px);
        background-color: #F9F9FB;
        overflow: scroll;
    }
}

.pdf-document::-webkit-scrollbar {
    display: none;
}

.css-mhc70k-MuiGrid-root>.MuiGrid-item {
    padding-left: 0px !important;
    padding-top: 0px !important;
    border-left: 1px solid #e4e4e4;
}

/* pdf document */

.pdf-viewer-document {
    display: flex;
    margin-bottom: 60px;
}

.react-pdf__Document {
    height: 100%;
}

.react-pdf__Page {
    margin-bottom: 20px;
}

.scrolling-page {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
}

.grid-document {
    width: 100%;
    height: 100%;
    display: flex;
}

.pdf-field-drag {
    display: inline-block;
    list-style: none;
    border: 1px solid #4b3cab33;
    vertical-align: top;
    font-size: 12px;
    cursor: move;
    /* color: var(--primary); */
    text-align: initial;
    cursor: move;
    border-radius: 3px;
    user-select: none;
    font-weight: 500;
    outline-width: 0;
    z-index: 0;
    resize: none;
    /* padding: 0px 2px; */
}

.pdf-select-field-drag {
    display: inline-block;
    list-style: none;
    border: 1px dashed var(--primary);
    vertical-align: top;
    font-size: 12px;
    cursor: move;
    /* color: var(--primary); */
    text-align: initial;
    cursor: move;
    border-radius: 3px;
    user-select: none;
    font-weight: 500;
    outline-width: 0;
    resize: none;
    /* padding: 0px 2px; */
}

input[type=number].pdf-select-field-drag::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number].pdf-field-drag::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.dropContainer {
    width: 100% !important;
    height: 100% !important;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    background-color: var(--primary);
}

.pdf-checkbox {
    width: 15px;
    height: 15px;
    display: inline-block;
    color: var(--primary);
    cursor: move;
}

.pdf-checkbox-select {
    width: 15px;
    height: 15px;
    display: inline-block;
    border: 1px dashed var(--primary) !important;
    cursor: move;
}

.pdf-drop {
    margin: 0 auto;
    width: 800px !important;
    height: 1050px !important;
    position: absolute;
    opacity: 1;
}

;

.resizable {
    resize: both !important;
    width: 10px !important;
    height: 13px !important;
    border-right: 2px solid #e0d7ff !important;
    border-bottom: 2px solid #e0d7ff !important;
    margin-left: 13.2%;
    margin-top: 2.6%;
}

.pdf-radio {
    width: 17px;
    height: 17px;
    border: 1px solid #9a9a9a;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    cursor: move;
}

.pdf-radio-active {
    width: 17px;
    height: 17px;
    border: 1px solid #9a9a9a;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 6px;
    border: 1px dashed var(--primary) !important;
    cursor: move;
}

.radio-select-field {
    width: 12px;
    height: 12px;
    background-color: var(--primary) !important;
    color: var(--primary);
    border-radius: 50%;
    display: inline-block;
    transition: opacity 0.2s;
    border: none;
    cursor: move;
}

.radio-select-field:focus {
    outline: none !important;
    border: 1px solid var(--primary) !important;
}

.checkbox-custom {
    background-color: #FFFFFF;
    border: 1px solid #e0d7ff;
    border-radius: 5px !important;
    cursor: move;
    /* justify-content: space-around; */
}

.icon-before {
    background: var(--primary);
    position: absolute;
    left: 0;
    top: 38%;
    height: 40%;
    width: 2px;
    content: "";
    transform: translateX(7px) rotate(-40deg);
    transform-origin: left bottom;
    border: none;
    cursor: move;
}

.icon-after {
    background: var(--primary);
    position: absolute;
    left: 0;
    bottom: 5px;
    height: 2px;
    width: 70%;
    content: "";
    transform: translateX(8px) rotate(-45deg);
    transform-origin: left bottom;
    cursor: move;
}

.custom-icon {
    border-radius: 0;
    width: 18px;
    height: 18px;
    line-height: unset;
    padding: 0;
    color: transparent;
    position: relative;
}

.undo {
    margin-left: 10px;
}

.zoom-text {
    margin-left: 20px;
}

.clockwise-img {
    margin-left: 20px;
}

.icon {
    fill: #737373;
    cursor: pointer;
    margin-left: 5px;
}

.icons {
    padding: 4px;
    margin-top: 3px;
    width: 35px !important;
    height: 35px !important;
}

/* .react-pdf__Page__canvas {
    margin: 0 auto;
} */

.page-canvas {
    /* width: 80%; */
    margin: 0 auto;
    border: 1px solid var(--border-color);
    box-shadow: 0 0 10px -4px #c8c8c8;
}

.circle-container {
    border: 1px solid var(--primary);
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: #FFFFFF;
    z-index: 100;
}

.top-resize {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.bottom-resize {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.signature_img {
    top: unset !important;
    left: unset !important;
    position: absolute !important;
}

.css-1yllih9-MuiPaper-root-MuiPickersPopper-paper {
    box-shadow: var(--box-shadow) !important;
}