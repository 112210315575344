.main_div{
    display: grid;
    grid-template-columns: 1fr 20px 1fr 20px 1fr;
    border-bottom: 1px solid rgba(149, 157, 165, 0.2);
    padding-top: 20px;
    padding-bottom: 20px;

}
.image_div{
    text-align: center ;
}
.text_div{
    padding-top: 45px;
    padding-right: 80px;
    padding-left: 80px;
    height: 45%;
}
.btn_div{
    text-align:center;
     padding: 50px ;
     
}
.btn_div_keyword{
    text-align:center;
     /* padding: 50px ; */
     
}
.all_div{
    width: 100%;
    text-align: center;
}
.btn_div button{
   font-size: 14px;
   text-transform:none;
}
.btn_div label{
   font-size: 14px;
   text-transform:none;
}
.btn_div input{
    font-size: 1rem;
    color: #3498db; 
    font-weight: bold;
    outline: none;
    border: 1px solid gray;
    border-radius: 5px;  
    padding: 0.5rem 0.7rem;
    color: gray;
    transition: 0.1s ease-out;
    
}
.btn_div input:focus{
    border-color: #3c80ff;
    color:#3c80ff;
    font-weight: bold;
    
}
.top_div{
    border: 1px solid rgba(149, 157, 165, 0.2);
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.next_btn{
    width: 100%;
    text-align: end;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
}
.input_value_open{
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    color: #1C2025 !important;
}
