.main_wrap {
    padding: 0px 30px 30px;
}

.new_contact_wrap {
    width: 500px;
    position: absolute;
    translate: -50% -50%;
    top: 50%;
    left: 50%;
    background-color: var(--white);
    border-radius: 10px;
    padding: 40px;
}

.close {
    position: absolute !important;
    top: 8px;
    right: 8px;
}

.new_contact_field_wrap {
    padding: 13px 0;
}

.new_contact_field_wrap input:focus {
    background-color: transparent;
}

.add_btn {
    margin-top: 15px;
}