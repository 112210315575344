.grid_item {
    padding: 20px 0px;
    /* border: 1px solid #ccc;  */
  }
  .grid_text_label{
    display: grid;
    grid-template-columns: 38% 60%;
    gap: 10px;
    margin-bottom: 1.5rem !important;
  }
  .grid_container {
    display: grid;
    grid-template-columns: 38% 49%;
    gap: 10px; /* Optional: Adds space between columns */
  }