.main_centilio_docs {
    overflow-y: scroll;
    height: 100vh;
}


.main_centilio_docs::-webkit-scrollbar-track {
    background-color: inherit;
}

.main_centilio_docs::-webkit-scrollbar {
    max-height: 12px;
    max-width: 5px;
    min-height: 8px;
    min-width: 5px;
    background-color: #ffffff;
}

.main_centilio_docs::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px #0000004d;
    background: #b8b8b8;
    border-radius: 5px;
    width: 5px;
    height: 5px;
}


.docs_text_main {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 22px;
    font-family: var(--primary-font-family);
    margin: 30px;
    padding: 30px;
    font-weight: 600;
}

.docs_text_img {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;

}

.docs_img_container {
    display: flex;
    gap: 30px;
    justify-content: center;
    margin-top: 40px;

}

.docs_image {
    width: 540px;
    height: 450px;
}

.docs_large_img_text {
    /* max-width: 300px; */
    width: 300px;
    flex-wrap: wrap;
    font-size: 16px;
    font-family: var(--primary-font-family);
    color: var(--table-font-color);
    font-weight: 400;
}

.docs_svg_container {
    display: flex;
    gap: 5px;
}

.docs_svg {
    width: 20px;

}

.docs_text_title {
    font-size: 24px;
    font-family: var(--primary-font-family);
    color: var(--table-font-color);
    font-weight: 600;
}

.docs_text {
    font-size: 16px;
    font-family: var(--primary-font-family);
    color: var(--table-font-color);
    font-weight: 400;
    /* background-color: red; */

}