.card_main_contain {
    display: flex;
    justify-content: center;
    border: 1px solid var(--border-color);
    min-width: 500px;
    margin-top: 30px;
    padding: 80px 30px;
    gap: 30px;
    box-shadow: var(--box-shadow) !important;
}

.invite_main_contain {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 100px);
}

.text_container {
    line-height: 20px;
}

.header_text {
    display: flex;
    justify-content: center;
    font-size: 22px;
    color: var(--header-color);
    font-weight: 800;
}

.body_text {
    display: flex !important;
    justify-content: center !important;
    text-align: center;
    color: var(--table-font-color) !important;
    width: 650px;
    padding: 20px 0px 5px 0px;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 30px;
}

.credit_text {
    display: flex !important;
    justify-content: center !important;
    color: var(--table-header-font) !important;
    padding: 8px 0 0 0;
    font-size: 12px !important;
}

.centilio_text {
    display: flex !important;
    justify-content: center !important;
    color: var(--table-font-color);
    font-size: 14px;
    font-weight: 400 !important;
}

.trial_button {
    text-transform: capitalize !important;
    font-size: 14px !important;
    font-family: var(--primary-font-family) !important;
    padding: 10px 20px !important;
}

.trial_button_wrapper {
    display: flex !important;
    justify-content: center !important;
    padding-top: 30px !important;
}

.card_render_contain {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-top: 10px;
}

.card_initiate_contain {
    display: flex;
    justify-content: flex-start;
    color: var(--font-color);
    font-family: var(--primary-font-family);
    font-size: 14px;

}

.invitation_emails {
    display: flex;
    color: var(--font-color);
    font-family: var(--primary-font-family);
}

.email_align_content {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    width: 500px;
    font-size: 14px;
}