.table_wrap th,
.table_wrap td {
    padding: 3.3px 0px 3.3px 6px;
}

.table_wrap_main *::-webkit-scrollbar {
    max-height: 3px;
    max-width: 5px;
    background-color: #ffffff;
}

.select_pagination {
    box-shadow: var(--box-shadow) !important;
}

.pagination_item {
    display: flex !important;
    justify-content: center !important;
    font-family: var(--primary-font-family) !important;
}

.search_bar {
    /* max-width: 300px; */
}

.search_bar:focus {
    background-color: transparent;
}

.table_nav {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    min-height: 90px;
    gap: 20px;
    align-items: center;
    flex-wrap: wrap;
}

.nav_item {
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.arrow_icons {
    min-width: 0px !important;
    padding: 2px;
}

.nav_item:hover {
    display: flex;
    align-items: center;
    white-space: nowrap;
    background-color: transparent;
}

.table_container::-webkit-scrollbar-track {
    background-color: inherit;
}

.table_container::-webkit-scrollbar {
    max-height: 12px;
    max-width: 5px;
    min-height: 8px;
    min-width: 5px;
    background-color: #ffffff;
}

.table_container::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px #0000004d;
    background: #b8b8b8;
    border-radius: 5px;
    width: 5px;
    height: 5px;
}

.table_container_no_data {
    min-height: calc(100vh - 280px);
}

.table_container {
    /* height: calc(100vh - 280px); */
    position: relative;
    border-radius: 12px !important;
    max-height: calc(100vh - 238px);
    overflow: auto;
}

.table_container td {
    border: unset !important;
}

.table_container_wrap {
    border: 1px solid var(--border-color);
    box-shadow: rgba(140, 152, 164, 0.075) 0px 0.375rem 0.75rem !important;
    border-radius: 12px;
    overflow: hidden;
}

.table_container_wrap table {
    border: unset;
}

@media (max-width: 888px) {
    .table_container {
        max-height: calc(100vh - 280px);
    }

    .table_container_no_data {
        min-height: calc(100vh - 280px);
    }
}

@media (max-width: 460px) {
    .table_container {
        max-height: calc(100vh - 300px);
    }

    .table_container_no_data {
        min-height: calc(100vh - 300px);
    }
}

.total_record {
    white-space: nowrap;
    font-size: 14px;
    color: var(--table-font-color);
    font-family: var(--primary-font-family);
}

.search_box {
    display: flex !important;
    align-items: center !important;
}