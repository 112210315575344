.upsec_area{
    text-align: center;
    padding: 10px;
    width: 100%;
    height: 360px;
    /* border: 1px dashed var(--primary); */
    border-radius: 5px;
    background: #f8f7ff;
    margin-bottom: 20px;
    position: relative;
}
.upsec_areas{
    padding: 10px;
    text-align: center;
    width: 100%;
    height: 360px;
    /* border: 1px dashed #0059ff; */
    border-radius: 5px;
    background: #ded9ff;
    margin-bottom: 20px;
    position: relative;
}
.browse_inn_part{
    width: 100%;
    height: 100%;
}
.before_upload{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.box_img{
    width: 89px;
    height: 137px;
}
.upload_file_text{
    color: #ffff;
    width: 214px;
}
.or {
    display: block;
    margin-bottom: 14px;
    color: #ffff
}
.Upload_head{
    width: 100%;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}
.Upload_head_name{
    font-size: 42px;
    line-height: 56px;
    font-weight: 900;
    padding: 10px;
}

.btn_div{
    position: relative;
    border-radius: 4px;
    color: rgb(33, 33, 33);
    background-color: rgb(255, 255, 255);
}
.btn_div button{
    cursor: pointer;
    user-select: none;
    margin: 0px;
    outline: 0px;
    border: 0px;
    width: auto;
    overflow: visible;
    /* display: flex; */
    -webkit-box-align: center;
    align-items: center;
    color: inherit;
    background-color: transparent;
    transition: background-color 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
    font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    padding: 15px 24px;
    height: 54px;
    position: relative;
    border-radius: 4px 0px 0px 4px;
    text-transform: uppercase;
}