.err_body {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(0, 50%);
}

.err_img {
    width: 335px;
    height: 290px;
}

.err_container {
    padding: 30px;
}

.err_number {
    font-size: calc(1.625rem + 4.5vw);
    font-weight: 600;
    line-height: 1.2;
    color: var(--header-color);
}

.err_text {
    font-size: 1.09375rem;
    font-weight: 400;
    color: var(--table-header-font);
    margin-bottom: 1rem;
}

.err_btn {
    font-size: 14px;
    text-transform: inherit !important;
    font-family: var(--primary-font-family);
    font-weight: 400;
    padding: 0.6125rem 1rem;
}