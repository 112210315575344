.main_wrap {
    padding: 0px 30px 30px;
}

.new_contact_wrap {
    width: 500px;
    position: absolute;
    translate: -50% -50%;
    top: 50%;
    left: 50%;
    background-color: var(--white);
    border-radius: 10px;
    padding: 40px;
}

.close {
    position: absolute !important;
    top: 8px;
    right: 8px;
}

.new_contact_field_wrap {
    padding: 13px 0;
}

.new_contact_field_wrap input:focus {
    background-color: transparent;
}

.add_btn {
    padding: 14px 25px !important;
    margin-top: 10px !important;
    text-transform: unset !important;
}

.new_contact {
    color: var(--header-color) !important;
    font-weight: 600;
    margin: 0;
    text-align: left;
    font-size: Inter !important;
}

.contact_text_field {
    border: none !important;
}

.name_textfield {
    display: flex !important;
    justify-content: space-between !important;
    gap: 5px;
    width: 100%;
    padding-top: 10px;
}

.css-1fu7jd5-MuiButtonBase-root-MuiButton-root {
    text-transform: unset !important;
}

.upload-body {
    display: flex;
    align-items: center;
    right: 0;
}