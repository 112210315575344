.btn{
    /* height: 56px; */
    width: 300px;
}
.sub_btn{
    width: 150px;
}
.selector_timezone{
    width: 300px;
}

 .selector_domain:focus{
    box-shadow: none !important;
} 
/* .controler legend{
font-size: 1.75em;
} */
.create_btn{
    padding-top: 10px;
    padding-right: 30px;
}
.submit_btn{
    text-align: end;
    padding-top: 15px;
}
.grid_container {
    display: grid;
    grid-template-columns: 49% 49%;
    gap: 10px; /* Optional: Adds space between columns */
  }
  
  .grid_item {
    padding: 20px 0px;
    /* border: 1px solid #ccc;  */
  }
  .grid_text_label{
    display: grid;
    grid-template-columns: 38% 60%;
    gap: 10px;
    margin-bottom: 1.5rem !important;
  }