/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap'); */

* {
    box-sizing: border-box;
    font-family: 'Inter';
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
    overflow: hidden;
}

.main_wrap {
    min-height: 100vh;
    display: flex;
    width: 100%;
    background-color: #F9FAFC;
    font-family: 'Inter';
}

.main_content {
    width: 100%;
    height: 100%;
}

:root {
    --primary: #377DFF;
    --secondary: #132144;
    --tertiary: #1b0c30;
    --font: #193037;
    --white: #ffffff;
    --black: #101316;
    --success: #23ab94;
    --danger: #ed4c78;
    --border-width: 1px;
    --border-top: 1px 0 0 0;
    --border-bottom: 0 0 1px 0;
    --border-left: 0 0 0 1px;
    --border-left-right: 0 1px 0 1px;
    --border-right: 0 1px 0 0;
    --border-style: solid;
    --border-color: rgba(231, 234, 243, 0.70);
    --box-shadow: 0 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, .175);
    --box-shadow-hover: 0 0.1875rem 0.75rem #8c98a440;
    --table-head-bg: #fafafd;
    --radius-primary: 10px;
    --common-title: #000;
    --dashboard-background: #F9FAFC;
    --primary-font-family: Inter;
    --font-color: #132144;
    --table-font-color: #1E2022;
    --table-header-font: #677788;
    --header-color: #171a1fff;
    --sidebar-icon-color: #387cff;
    --field-border-color: #767676;
    --field-border-danger: #E44E48;
}

.material-icons {
    color: #f9441a;
}

.material-icons {
    color: #f9441a;
}

.close-icons {
    display: flex;
    text-align: center;
    justify-content: center;
}

.Toastify__toast--success {
    border: 1px solid #77EC95;
    color: #008A2E !important;
    background: #D7F5DD !important;
    box-shadow: 0 0 10px 1px #ddddddc7 !important;
    border-radius: 8px !important;
    font-size: 14px !important;
}

.Toastify__toast--warning {
    border: 1px solid #fdf8e9;
    color: #c59505 !important;
    background: #fdf8e9 !important;
    box-shadow: 0 0 10px 1px #ddddddc7 !important;
    border-radius: 8px !important;
    font-size: 14px !important;
    font-family: var(--primary-font-family) !important;
    line-height: 20px;
}

.recipent_select {
    text-align: right;
}

.welcome_page_select {
    margin-top: 2px !important;
    top: 115px !important;
    translate: -25px !important;
    min-width: 200px !important;
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, .18) !important;
}

.recipient_select {
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, .18) !important;
}

.recipient_select ul {
    padding-top: unset !important;
    padding-bottom: unset !important;
}

.recipient_select::-webkit-scrollbar-track {
    background-color: inherit;
}

.recipient_select::-webkit-scrollbar {
    max-height: 12px;
    max-width: 5px;
    min-height: 8px;
    min-width: 5px;
    background-color: #ffffff;
}

.recipient_select::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px #0000004d;
    background: #b8b8b8;
    border-radius: 5px;
    width: 5px;
    height: 5px;
}

.select_condition_more_options {
    top: 60px !important;
    position: absolute;
    box-shadow: var(--box-shadow) !important;
}

.select_fields_method {
    top: 300px !important;
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, .18) !important;
}

.css-1xfcrj6-MuiTableCell-root {
    font-family: Inter !important;
}

.react-calendar__tile:disabled {
    background-color: transparent;
    cursor: no-drop;
}

.react-calendar__navigation button:disabled {
    background-color: transparent;
    cursor: no-drop;
}

.filter-icon-img {
    width: 6px;
    height: 6px;
    display: flex;
    justify-content: center;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
    padding: 8px !important;
}

.css-r8u8y9 {
    padding: 8px !important;
}