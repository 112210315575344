.jodit_top {
    display: grid;
    justify-content: center;
    position: relative;

}

.jodit_table {
    border: 1px solid rgba(149, 157, 165, 0.2);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.jodit .jodit-workplace {
    height: 297mm !important;
    width: 250mm;
}

/* .jodit-status-bar{
    display: none !important;
}*/
.jodit-toolbar__box {
    color: #fff !important;
}

.jodit-container {
    border: none !important;
}
.rephrase_btn {
    text-align: end ;
}

.rephrase_btn button {
    text-transform: capitalize;
    border-radius: 10px;
    border: 1px solid rgba(149, 157, 165, 0.2);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.rephrase_btn img {
    margin-right: 10px;
}

.document_popup {
    background-color: white;
    position: absolute;
    top: 31%;
    right: 24%;
    width: 56%;
    height: 29%;
    border-radius: 10px;
    border: 1px solid rgba(149, 157, 165, 0.2);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.document_title {
    padding: 14px;
    display: flex;
    justify-content: space-between;
    background-color: #F8FAFD;
}

.document_popup textarea {
    height: 238px;
    width: 573px;
    border: none;
    resize: none;
    background: white;
    padding: 10px;

}

.approve_btn {
    text-align: end;
    padding-right: 5px;
    /* padding-top: 10px; */
}

.approve_btn button {
    color: #21A366;
}

.jodit_buttons {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    /* text-align: end; */
    padding: 10px;
    /* background-color: #f9f9f9; */
}
.jodit_buttons button{
    margin-right: 10px;
    text-transform: capitalize;
}
.document{
    padding: 10px;
    cursor: pointer;
}
.document_unselect{
    padding: 10px;
    cursor: not-allowed;
}
.document:hover{
   background-color: #F8FAFD;
}
.document span{
   padding-right: 10px;
}
.document_unselect span{
   padding-right: 10px;
}
.jodit-wysiwyg p{
    margin: 0px !important;
}
.jodit-add-new-line_after{
    display: none !important;
}
.model_text_area{
    width: 100%;
    height: 85%; 
    resize: none;
}


@media (max-width: 3000px) {
    .model_text_area{
        width: 100%;
        height: 70%; 
        resize: none;
    }
} 

@media (max-width: 1280px) {
    .model_text_area{
        width: 100%;
        height: calc(100vh - 400px); 
        resize: none;
    }
} 
@media (max-width: 1024px) {
    .model_text_area{
        width: 100%;
        height: calc(100vh - 415px); 
        resize: none;
    }
} 
@media (max-width: 912px) {
    .model_text_area{
        width: 100%;
        height: calc(100vh - 415px); 
        resize: none;
    }
} 

@media (max-width: 853px) {
    .model_text_area{
        width: 100%;
        height: calc(100vh - 415px); 
        resize: none;
    }
}
 @media (max-width: 770px) {
    .model_text_area{
        width: 100%;
        height: calc(100vh - 415px); 
        resize: none;
    }
} 
@media (max-width: 540px) {
    .model_text_area{
        width: 100%;
        height: calc(100vh - 407px); 
        resize: none;
    }
} 

@media (max-width: 430px) {
    .model_text_area{
        width: 100%;
        height: calc(100vh - 400px); 
        resize: none;
    }
} 
@media (max-width: 375px) {
    .model_text_area{
        width: 100%;
        height: calc(100vh - 400px); 
        resize: none;
    }
} 
@media (max-width: 280px) {
    .model_text_area{
        width: 100%;
        height: calc(100vh - 465px); 
        resize: none;
    }
} 
.jodit-container{
    height: calc(100vh - 250px) !important;
}