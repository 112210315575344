.main_schedule_home {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 100px);
}

.main_content_book {
    display: flex;
    gap: 10px;
    padding: 1rem;
    border-bottom: 1px solid var(--border-color);
}

.centilio_sign {
    font-size: 22px !important;
    color: var(--header-color) !important;
    font-weight: 600 !important;
    font-family: var(--primary-font-family) !important;
    text-wrap: nowrap;
}