.btn{
    height: 56px;
    width: 300px;
}
.sub_btn{
    width: 150px;
}
.selector_timezone{
    width: 300px;
}
/* .controler legend{
font-size: 1.75em;
} */