.delete-modal-mask {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: table;
    text-align: center;
    transition: opacity 0.3s ease;
    cursor: default;
  }
  
  .delete-modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  
  .delete-modal-container {
    width: 400px;
    margin: 0px auto;
    padding: 5px 20px;
    background-color: #fff;
    border-radius: 10px;
    transition: all 0.3s ease;
  }
  
  .delete-modal-body {
    padding: 20px 20px;
  }

  .delete-scroll {
    width: 100%;
    padding: 0px 0px 10px 1px;
    align-items: flex-start;
  }

.delete-scroll::-webkit-scrollbar-track {
    background-color: inherit;
}

.delete-scroll::-webkit-scrollbar {
    max-height: 12px;
    max-width: 5px;
    min-height: 8px;
    min-width: 5px;
    background-color: #ffffff;
}

.delete-scroll::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px #0000004d;
    background: #b8b8b8;
    border-radius: 5px;
    width: 5px;
    height: 5px;
}
  
  .delete-modal-text {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    color: var(--table-font-color);
  }

  .delete-modal-buttons {
    padding-top: 30px;
  }  
  
  .delete-container-cancel {
    height: 42.59px !important;
    padding: 10.8px 17px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    margin: 0px 10px;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    display: inline-block;
    color: var(--table-header-font);
    cursor: pointer;
  }
  
  .delete-container-cancel:hover {
    color: var(--primary);
  }
  
  .delete-modal {
    padding: 10.8px 17px !important;
    font-size: 14px !important;
    margin: 0px 10px;
    border-radius: 6px;
    display: inline-block !important;
    color: #fff;
    background-color: var(--primary) !important;
    cursor: pointer;
  }

  .delete-modal:hover {
    background-color: rgb(38, 87, 178) !important;
  }

  .delete_template_name {
    font-size: 14px !important;
    color: var(--table-header-font);
    line-height: 20px !important;
    display: list-item;
    width: calc(80%);
    text-overflow: ellipsis !important;
    white-space: nowrap;
    overflow: hidden;
  }

  .delete_template_documents {
    font-size: 14px !important;
    color: var(--table-font-color);
    line-height: 20px !important;
    display: flex;
    margin: 3px 0px 3px 0px;
  }

  .delete_template_docs {
    display: flex;
    margin-top: 10px !important;
    font-size: 18px;
    font-weight: 600;
    color: var(--table-font-color);
    justify-content: center;
  }

  .linked_documents .css-1c9hx3k-MuiList-root{
    padding: 0 !important;
  }

  .linked_documents .css-qg4d76 {
    padding: 4px 16px !important;
  }

  .linked_documents .css-wlxkvf {
    padding: 0px 0px !important;
  }

  .linked_documents .css-1j3qxzz-MuiListItem-root {
    padding: 4px 16px !important;
  }