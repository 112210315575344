.payment_top_div {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
}

.payment_details {
  width: 50%;
  background-color: #f0f4ff;
}

.left_side {
  padding: 40px;
}

.order {
  font-family: var(--primary-font-family);
  font-size: 16px;
  font-weight: bold;
  padding: 15px 0px;
  color: var(--table-header-font);
  display: flex;
  justify-content: center;
}

.plans {
  font-family: var(--primary-font-family);
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 15px;
  color: var(--table-font-color)
}

.pay_button {
  width: 100%;
  padding: 10px !important;
  text-transform: capitalize !important;
}

.option_change {
  color: dodgerblue;
  width: 15%;
  font-weight: bold;
  font-size: 14px;
  padding-bottom: 15px;
  cursor: pointer;
}

.your_details {
  /* border-top: 1px solid #e0e2ed; */
  border-bottom: 1px solid #e0e2ed;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-bottom: 25px;
}

.name {
  font-size: 16px;
  font-weight: bold;
  color: var(--table-font-color);
  text-transform: capitalize;
}

.mail {
  font-size: 13px;
  color: var(--table-header-font);
  font-weight: bold;
  margin-top: 10px;
}

.bill {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.bill div {
  color: var(--table-font-color);
}
.plan_type{
  display: flex;
  justify-content: flex-start;
  gap: 15px;
}
.plan_text{
  font-size: 16px;
  font-weight: bold;
  color: var(--table-font-color);
}
.plan_value{
  font-size: 16px;
  font-weight: bold;
  color: dodgerblue;
}

.users {
  color: var(--table-font-color);
}

.all_users {
  color: var(--table-header-font);
}

.promo_name {
  color: var(--table-font-color);
}

.sub_total div {
  color: var(--table-font-color);
}

.due div {
  color: var(--table-font-color);
}

.bill select {
  width: 80%;
  height: 40px;
  border-color: #e2e2e2;
  border-radius: 5px;
  font-size: 14px;
}

.bill_price {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  align-items: center;
}

.bill_price select {
  border-color: #e2e2e2;
  border-radius: 5px;
  width: 80%;
  height: 40px;
  font-size: 14px;
}

.moreoption {
  padding-top: 24px;
  padding-bottom: 20px;
  font-size: 11px;
  color: #a9a9a9;
}

.promocode {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
  border-top: 1px solid #e0e2ed;
  margin-top: 20px;
}

.promo_add {
  color: dodgerblue;
  cursor: pointer;
}

.sub_total {
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 20px;
  border-top: 1px solid #e0e2ed;
}

.due {
  display: flex;
  font-size: 22px;
  font-weight: bold;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 40px;
  border-top: 1px solid #e0e2ed;
}

.plans select {
  width: 130px;
  height: 40px;
  border-color: #e2e2e2;
  border-radius: 5px;
  font-size: 16px;
}

.back_button {
  cursor: pointer;
  border: none;
  text-transform: capitalize !important;
  font-size: 12px !important;
}

.select_payment {
  display: flex;
  width: 100%;
  justify-content: end;
}

.submit_btn {
  padding: 20px 0px;
}

.sign_order_input {
  width: 39% !important;
}

.promo_button {
  width: 50%;
  margin-left: 10px !important;
  right: 0px !important;
  text-transform: capitalize !important;
}

.promo_applied {
  font-size: 12px;
  color: var(--success);
  margin-top: 5px;
}

.promo_invalid {
  font-size: 12px;
  color: var(--danger);
  margin-top: 5px;
}