.payment_div {
  width: 100%;
  height: 100%;
  overflow: scroll;
  position: absolute;
}
.payment_div::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
.payment_div::-webkit-scrollbar-thumb {
  background: #FF0000;
}

.price_title{
    font-size: 40px;
    font-weight: bold;
    padding: 50px;
    color: var(--header-color);
}


  .checkbox_div{
    display: flex;
    justify-content: center;
    margin-bottom: 48px;
  }
  .tab_bg{
    background: #F9F6FF;
    border-radius: 78px;
    display: inline-block;
    margin: 0 auto;
    width: 256px;
    height: 56px;
  }
  .tab_bg div{
    display: inline-block;
    padding: 18px 8px 18px;
    cursor: pointer;
  }
  .tab_bg .monthly_tab{
    padding-left: 11px;
  }
  .tab_bg .annually_tab{
    padding-right: 11px;
  }
  .tab_bg span{
    font-size: 14px;
  }
  .monthly_tab .active{
    padding: 16px 8px 18px;
  }
  .annually_tab .active{
    padding: 16px 8px 18px;
  }
 .active span{
    background: #FFFFFF;
    font-size: 14px;
    box-shadow: 2px 6px 8px rgb(70 23 163 / 6%);
    border-radius: 78px;
    color: #6D24FF;
    padding: 13px 20px 13px;
  }