.pdf_main_content{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}
.pdf_main_flex{
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background: rgb(242, 246, 255);
}
.pdf_left_site{
    position: relative;
    display: flex;
    flex: 1 1 0%;
    max-width: 100%;
}
.pdf_right_site{
    transition: margin 0.5s ease 0s;
    width: 480px;
    padding: 64px;
    margin-right: 0px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    box-shadow: rgba(26, 26, 26, 0.2) 0px 0px 4px;
    background-color: rgb(255, 255, 255);
    overflow-y: scroll;
    z-index: 1;
}
.pdf_left_full{
    -webkit-box-pack: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    flex: 1 1 0%;
    gap: 16px;
}
.pdf_left_img{
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    gap: 8px;
}
.pdf_left_img_color{
    position: relative;
    background: white;
    box-shadow: rgba(26, 26, 26, 0.2) 0px 0px 2px;
    color: rgb(26, 26, 26);
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    
}
.pdf_left_file_name{
    text-align: center;
    padding-top: 10px;
}
.pdf_right_done{
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    gap: 8px;
}
.pdf_right_text{
    flex: 1 1 0%;
    font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    color: rgb(26, 26, 26);
}
.pdf_download_area{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.pdf_download_file_name{
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.pdf_download_file{
    display: flex;
    gap: 2px;
    cursor: pointer;
}
.pdf_download_file_first{
    font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: rgb(26, 26, 26);
    border-bottom: 1px dashed rgb(163, 163, 163);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.pdf_download_file_secound{
    font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    color: rgb(117, 117, 117);
}
.pdf_download_share:hover{
    background-color: #377DFF !important;
    color: aliceblue;
}
.pdf_download_start_over{
    background-color: #ffffff !important;
    color: rgb(44, 44, 44) !important;
    border: 1px solid rgb(232, 232, 232) !important;
}
.pdf_download_start_over:hover{
    background-color: #e5e5e5 !important;
}
.pdf_modal_container{
    box-shadow: rgba(26, 26, 26, 0.2) 0px 0px 24px;
    display: flex;
    flex-direction: column;
    z-index: 600;
    background-color: rgb(255, 255, 255);
    box-sizing: content-box;
}
.pdf_modal_padding{
    /* padding: 32px; */
    display: flex;
    /* flex-direction: column;
    flex: 1 1 auto; */
}
.pdf_modal_close{
    cursor: pointer;
    color: rgb(33, 33, 33);
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 12px;
    box-sizing: content-box;
}
.pdf_modal_filename{
    font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: rgb(26, 26, 26);
    padding-bottom: 8px;
}