.view_doc_body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400;
    background-color: #fff;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    box-shadow: 24;
    padding: 40px;
}

.view_doc_avatar {
    display: flex;
    gap: 20px;
    margin-top: 10px;
    margin-left: 10px;
}

.doc_Bold_lightText {
    color: var(--table-font-color);
}

.doc_view_title {
    color: var(--header-color) !important;
    font-family: var(--primary-font-family);
    font-size: 22px;
    font-weight: 600;
    text-align: center;
}

.doc_view_lightText {
    color: var(--table-header-font);
    font-weight: 400;
    font-size: 14px;
    margin-top: 2px;
}

.doc_view_BoldText {
    color: var(--table-header-font);
    font-family: var(--primary-font-family);
    font-weight: 400;
    font-size: 14px;
    margin-left: 10px;
}

.docs_view_texField {
    display: flex;
    gap: 20px;
    align-items: baseline;
    margin-top: 5px;
}

.doc_view_button {
    color: #fff !important;
    background-color: #377DFF !important;
    text-transform: capitalize !important;
}

.access_input {
    font-family: var(--primary-font-family);
    color: var(--table-font-color);
    font-size: 14px;
}