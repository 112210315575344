.main_calender {
   border: none;
   padding: 20px;
   line-height: 25px;
   font-family: var(--primary-font-family) !important;
}

.main_time {
   display: flex;
   text-align: center;
   align-items: center;
   gap: 10px;
}

.main_container {
   border: 1px solid var(--border-color);
   border-radius: 6px;
   position: relative;
   max-height: calc(100vh - 170px);
   overflow: auto;
   width: 100%;
   max-width: 40%;
}

.disabled-date {
   pointer-events: none;
   color: #ccc;
   background-color: red;
}

.selected_day {
   font-weight: 400;
   font-size: 14px;
   font-family: var(--primary-font-family);
}

.day {
   display: flex;
   justify-content: center;
   color: var(--table-font-color);
   font-weight: 600;
}

.month_date {
   display: flex;
   justify-content: center;
   color: var(--table-header-font);
   font-size: 13px;
   margin-top: 5px;
}

.duration {
   display: flex;
   margin: 5px 5px 15px 5px;
   font-size: 13px;
   color: var(--table-header-font);
   justify-content: center;
}

.main_container::-webkit-scrollbar-track {
   background-color: inherit;
}

.main_container::-webkit-scrollbar {
   max-height: 12px;
   max-width: 5px;
   min-height: 8px;
   min-width: 5px;
   background-color: #ffffff;
}

.main_container::-webkit-scrollbar-thumb {
   -webkit-box-shadow: inset 0 0 6px #0000004d;
   background: #b8b8b8;
   border-radius: 5px;
   width: 5px;
   height: 5px;
}

.main_calender,
.main_calender *,
.main_calender *:before,
.main_calenderr *:after {
   text-decoration: none !important;
}

.main_calender button {
   border-radius: 50% !important;
}

.top_hiding_container {
   position: sticky;
   top: 0;
   text-align: center;
   z-index: 1;
   border-bottom: 1px solid var(--border-color);
   padding: 20px;
   background: #fff !important;
}

.back_icon {
   position: absolute !important;
   left: 0 !important;
   padding: 0px !important;
   margin: -2px 0px 0px 20px !important;
}

.days_container {
   justify-content: center;
   margin-top: 5px;
}

.days_container_disabled {
   display: flex;
   justify-content: center;
}

.centilio_sign {
   font-size: 16px !important;
   color: var(--header-color) !important;
   font-weight: 600 !important;
   font-family: var(--primary-font-family) !important;
   text-wrap: nowrap;
}

.main_calender_container {
   padding: 30px;
   border: 1px solid var(--border-color);
   box-shadow: var(--box-shadow) !important;
}

.day_text {
   display: flex;
   justify-content: center;
   font-weight: 600;
   font-size: 22px;
   font-family: var(--primary-font-family) !important;
   color: var(--header-color);
}

.time_btn {
   cursor: pointer;
   color: var(--primary);
   border-color: var(--primary);
   text-transform: unset;
   height: 52px;
   font-size: 14px !important;
   font-weight: 600 !important;
}

.time_btn:hover {
   border: 1.5px solid var(--primary);
   box-shadow: var(--box-shadow-hover);
}

.main_time_btn {
   display: grid;
   grid-template-columns: 1fr;
   max-width: 600px;
   min-width: 350px;
   gap: 10px;
   width: 90%;
}

.time_container {
   display: flex;
   justify-content: center;
}