.preview-wrapper {
    display: flex;
    justify-content: center;
    height: 100%;
    max-height: calc(100vh - 75px);
}

.pdf-preview-container {
    position: relative;
    margin: 20px 0 20px 0;
}

.preview-wrapper::-webkit-scrollbar-track {
    background-color: inherit;
}

.preview-wrapper::-webkit-scrollbar {
    max-height: 5px !important;
    max-width: 5px !important;
    min-height: 8px;
    min-width: 5px;
    background-color: #ffffff;
}

.preview-wrapper::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px #0000004d;
    background: #b8b8b8;
    border-radius: 5px;
    width: 3px !important;
    height: 3px !important;
}

.leftbar-container {
    line-height: 10px;
}

.pdf-viewer-preview {
    height: 125px !important;
    width: 102px;
    border: 1.5px solid #e4e4e4;
    background: #FFF;
    box-shadow: 0 0 4px 2px #4b3cab33;
    border-radius: 10px;
    cursor: pointer;
}

.pdf-select-preview {
    height: 125px !important;
    width: 102px;
    border: 1.5px solid var(--primary);
    background: #FFF;
    box-shadow: 0 0 4px 2px #4b3cab33;
    border-radius: 10px;
    cursor: pointer;
}

.preview-body {
    display: flex;
    text-align: center;
    justify-content: center;
    height: 100px;
    border-bottom: 1px solid rgba(231, 234, 243, 0.70);
}

.preview-body .react-pdf__Page__canvas {
    max-height: 97px;
}

.page-number {
    color: #111;
    font-weight: 500;
    font-size: 14px;
    width: 99px;
    height: 22px;
    display: flex;
    border-radius: 0 0 10px 10px !important;
    background-color: #F8FAFD;
    align-items: center;
    padding-left: 10px;
}

.react-pdf__Page {
    margin-top: 2px;
}

.tag-container {
    height: 70%;
    float: right;
    position: absolute;
    right: 0;
    top: 4px;
}

.tag-body {
    width: 26px;
    height: 8px;
    margin-top: 1px;
}