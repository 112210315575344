.decline_mask {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #13214440;
    display: table;
    transition: opacity 0.3s ease;
    cursor: default;
}

.decline_wrapper {
    display: table-cell;
    vertical-align: middle;
}

.decline_container {
    max-width: 600px;
    margin: 0px auto;
    background-color: #fff;
    border-radius: 10px;
    transition: all 0.3s ease;
    padding: 25px;
}

.decline_popup {
    cursor: pointer;
    margin: 15px 15px 0px 0px;
    opacity: .5;
    float: right;
    right: 15px;
    top: 15px;
    z-index: 1;
}

.decline_body {
    padding: 40px;
}

.decline_text {
    font-size: 22px;
    font-weight: 600;
    margin: 0;
    color: var(--header-color);
}

.decline_popup:hover {
    cursor: pointer;
    margin: 15px 15px 0px 0px;
    opacity: 1;
    float: right;
    right: 15px;
    top: 15px;
    z-index: 1;
}

.approvar_decline_popup {
    cursor: pointer;
    opacity: .5;
    float: right;
    right: 15px;
    top: 15px;
    z-index: 1;
}

.approvar_decline_popup:hover {
    opacity: 1;
}

.decline_textarea {
    padding-top: 20px
}

.decline_desc {
    width: 100%;
    height: 79px;
    padding: 10px 0;
    font-size: 14px;
    border: none;
    border: 0.0625rem solid var(--border-color);
    border-radius: 5px;
    padding: 10px;
    color: var(--table-font-color);
}

.decline_desc::placeholder {
    color: #a7a8ab6e;
}

.decline_desc:focus {
    outline: none !important;
    border: 1px solid var(--border-color);
}

.decline_buttons {
    padding-top: 20px;
}

.decline_submit {
    border-radius: 6px !important;
    display: inline-block;
    text-transform: capitalize !important;
}

.edit_doc {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.edit_text {
    font-family: var(--primary-font-family);
    font-size: 16px;
    font-weight: 600;
}

.edit_desc {
    font-family: var(--primary-font-family);
    font-size: 14px;
    line-height: 25px;
    padding: 10px 0px;
}