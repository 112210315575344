.table_value {
    height: calc(100vh - 150px);
    overflow-y: scroll;
    overflow-x: hidden;
}

.table_value::-webkit-scrollbar {
    display: none;
}

