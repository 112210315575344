.doc-mask {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f7f6ff;
  display: table;
  transition: opacity 0.3s ease;
  cursor: default;
}

.doc-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.doc-delete-container {
  margin: 0px auto;
  background-color: #fff;
  border-radius: 10px;
  transition: all 0.3s ease;
}

.doc-body {
  justify-content: space-between;
  padding: 30px;
  line-height: 35px;
}

.doc-text {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
  text-align: left;
  color: var(--header-color);
}

.doc-cancel {
  width: 175px !important;
  height: 48px !important;
  border-radius: 6px !important;
  display: inline-block;
  margin: 0 10px !important;
  text-transform: capitalize !important;
  border: 1px solid var(--border-color) !important;
  color: var(--table-header-font) !important;
}

.doc-cancel:hover {
  color: var(--primary) !important;
}

.doc-accept {
  width: 175px !important;
  height: 48px !important;
  border-radius: 6px !important;
  display: inline-block;
  margin: 0 10px !important;
  /* background-color: var(--primary) !important; */
  text-transform: capitalize !important;
}

.doc-info-text {
  text-align: left;
  color: #62637a;
  font-weight: 400;
  font-size: 14px;
  line-height: 50px;
}

.doc-name {
  width: 115px;
  display: table-cell;
  text-align: left;
  color: var(--table-header-font);
  font-weight: 400;
  font-size: 14px;
}

.doc-name-space {
  width: 20px;
  display: table-cell;
  text-align: left;
  font-size: 14px;
  color: #62637a;
  font-weight: 400;
}

.doc-right-text {
  display: table-cell;
  text-align: left;
  font-size: 14px;
  line-height: 18px;
  color: var(--table-font-color);
  font-weight: 400;
  word-break: break-all;
}

.doc-right-text-small-screen {
  display: table-cell;
  text-align: left;
  font-size: 14px;
  line-height: 18px;
  color: var(--table-font-color);
  font-weight: 400;
  word-break: break-all;
  white-space: collapse balance;
}

.terms {
  display: table-cell;
  text-align: left;
  font-size: 14px;
  line-height: 18px;
  color: var(--table-font-color);
  font-weight: 400;
}

.terms-link {
  font-weight: 500;
  text-decoration: none;
  color: var(--primary);
  pointer-events: none;
}

.doc-buttons {
  padding: 0px 10px 25px 20px;
  display: flex;
  justify-content: space-between;
}