.main_wrap {
    padding: 0px 30px 30px !important;
}

.card {
    height: calc(100vh - 200px);
}

.audit_logs {
    padding: 18px 22px !important;
    font-size: 16px;
    font-family: var(--primary-font-family) !important;
    color: var(--header-color);
    font-weight: 600;
    border-bottom: 1px solid var(--border-color);
}

.text_body {
    display: flex;
    align-items: center;
    padding: 15px 10px 15px 20px !important;
    width: 100%;
}

.text_body:hover {
    background-color: rgba(189, 197, 209, 0.2);
}

.text {
    font-size: 14px;
    font-weight: 400;
    font-family: var(--primary-font-family) !important;
    color: var(--table-font-color);
    max-width: 50%;
    padding-right: 15px;
}

.table_cell_name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.name_text {
    margin-right: 5px !important;
    color: var(--primary) !important;
    line-height: 20px;
}

.card_wrapper {
    width: 100%;
    height: 100%;
    max-height: 91%;
    overflow-y: scroll;
}

/* .card .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    
} */

.card_wrapper::-webkit-scrollbar-track {
    background-color: inherit;
}

.card_wrapper::-webkit-scrollbar {
    max-height: 12px;
    max-width: 5px;
    min-height: 8px;
    min-width: 5px;
    background-color: #ffffff;
}

.card_wrapper::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px #0000004d;
    background: #b8b8b8;
    border-radius: 5px;
    width: 5px;
    height: 5px;
}

.no_doc_found {
    position: fixed;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    color: var(--table-font-color);
}