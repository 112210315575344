/* .MuiFormControl-root .MuiOutlinedInput-root-MuiSelect-root{
   width: 35px ; 
} */

.MuiTabs-scroller .MuiTabs-indicator {
   background-color: #3727b0;
}

.plan_access_table::-webkit-scrollbar {
   display: none;
}