@font-face {
    font-family: 'Inter';
    src: url("https://us-cdn1.centilio.com/static_files/sign/font/Inter-Regular.ttf") format("truetype");;
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url("https://us-cdn1.centilio.com/static_files/sign/font/Inter-Regular.ttf") format("truetype");;
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url("https://us-cdn1.centilio.com/static_files/sign/font/Inter-SemiBold.ttf") format("truetype");;
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url("https://us-cdn1.centilio.com/static_files/sign/font/Inter-Bold.ttf") format("truetype");;
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('https://us-cdn1.centilio.com/static_files/sign/font/Inter.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
  src: url('https://us-cdn1.centilio.com/static_files/sign/font/Inter.woff2') format('woff2');
  }



/* 
@font-face { 
    font-family: 'Inter';
    src: url("https://us-cdn1.centilio.com/static_files/sign/font/Inter_24pt-Thin.ttf") format("truetype");
    font-weight: 100; 
    font-style: normal;
}

@font-face { 
    font-family: 'Inter';
    src: url("https://us-cdn1.centilio.com/static_files/sign/font/Inter_24pt-SemiBold.ttf") format("truetype");
    font-weight: 600; 
    font-style: normal;
}

@font-face { 
    font-family: 'Inter';
    src: url("https://us-cdn1.centilio.com/static_files/sign/font/Inter_24pt-Regular.ttf") format("truetype");
    font-weight: 400; 
    font-style: normal;
}

@font-face { 
    font-family: 'Inter';
    src: url("https://us-cdn1.centilio.com/static_files/sign/font/Inter_24pt-Medium.ttf") format("truetype");
    font-weight: 500; 
    font-style: normal;
}

@font-face { 
    font-family: 'Inter';
    src: url("https://us-cdn1.centilio.com/static_files/sign/font/Inter_24pt-Light.ttf") format("truetype");
    font-weight: 300; 
    font-style: normal;
}

@font-face { 
    font-family: 'Inter';
    src: url("https://us-cdn1.centilio.com/static_files/sign/font/Inter_24pt-ExtraLight.ttf") format("truetype");
    font-weight: 200; 
    font-style: normal;
}

@font-face { 
    font-family: 'Inter';
    src: url("https://us-cdn1.centilio.com/static_files/sign/font/Inter_24pt-ExtraBold.ttf") format("truetype");
    font-weight: 800; 
    font-style: normal;
}

@font-face { 
    font-family: 'Inter';
    src: url("https://us-cdn1.centilio.com/static_files/sign/font/Inter_24pt-Bold.ttf") format("truetype");
    font-weight: 700; 
    font-style: normal;
}

@font-face { 
    font-family: 'Inter';
    src: url("https://us-cdn1.centilio.com/static_files/sign/font/Inter_24pt-Black.ttf") format("truetype");
    font-weight: 900; 
    font-style: normal;
} */

@font-face {
    font-family: 'Arial';
    src: url("https://us-cdn1.centilio.com/static_files/sign/font/Arial Regular.ttf") format("truetype");;
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Courier New';
    src: url("https://us-cdn1.centilio.com/static_files/sign/font/Courier New Regular.ttf") format("truetype");;
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Calibri';
    src: url("https://us-cdn1.centilio.com/static_files/sign/font/calibri-regular.ttf") format("truetype");;
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url("https://us-cdn1.centilio.com/static_files/sign/font/OpenSans-Regular.ttf") format("truetype");;
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Times New Roman';
    src: url("https://us-cdn1.centilio.com/static_files/sign/font/times new roman Regular.ttf") format("truetype");;
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Verdana';
    src: url("https://us-cdn1.centilio.com/static_files/sign/font/verdana-regular.ttf") format("truetype");;
    font-weight: 400;
    font-style: normal;
}