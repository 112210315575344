.top_bar {
    position: relative;
    z-index: 10;
    border-bottom: 1px solid rgb(232, 232, 232);
    background-color: white;
    top: -1px
}

.top_bar_main {
    padding: 0px 16px;
    display: flex;
    flex-wrap: nowrap;
    height: 56px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}

.logo_text {
    padding-top: 5px;
    font-weight: 600;
    font-size: 20px;
    margin-left: 6px;
    font-style: normal;
    font-family: var(--primary-font-family);
    color: var(--header-color);
    line-height: 30px;
    letter-spacing: 0.188px;
}

.top_bar_img {
    display: flex;
    height: 74%;
    padding-right: 12px;
}

.top_bar_logo {
    display: flex;
    height: 100%;
    padding-right: 12px;
}

.logo_img {
    height: 40px;
    width: 40px;
}

.menu_bar {
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-flex: 1;
    flex-grow: 1;
    height: 100%;
}

.tools_menu {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0px 18px;
    height: 100%;
}

.tools_padding {
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    transition: color 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s, background-color 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
    border-bottom: 2px solid rgb(0, 85, 255);
    gap: 4px;
    cursor: pointer;
    color: rgb(0, 85, 255);
}

.head_flex {
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow: hidden;
    margin: 0px;
    padding: 0px;
    height: 100%;
    align-items: baseline;
}

.head_top {
    display: flex;
    height: 100%;
}

.head_names {
    align-items: center;
    font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgb(33, 33, 33);
    white-space: nowrap;
    height: 100%;
    padding: 14px 12px;
    cursor: pointer;
    transition: color 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
}

.head_button {
    padding: 8px 12px;
}

.head_names:hover {
    color: rgb(152, 152, 152);
}

.head_names:active {
    color: rgb(202, 202, 202);
}

.top_bar_tools {
    /* z-index: 9;
    border-bottom: 1px solid rgb(232, 232, 232);
    position: absolute;
    width: 100%;
    background: white;
    -webkit-transition: all 0.5s ease-out; */
}

.top_all_tools {
    /* position: absolute; */
    top: 100%;
    left: 0px;
    width: 100%;
    right: 0px;
    overflow: hidden auto;
    z-index: 450;
    border-top: 1px solid rgb(224, 224, 224);
    background-color: white;
    display: flex;
    /* margin-top: 60px; */
}

.paper {
    background-color: unset;
}

.top_selected_tools {
    padding: 16px 0px;
    width: 100%;
    max-width: 1440px;
    /* margin: 0px auto; */
}

.top_tools_grid {
    grid-auto-flow: column;
    /* grid-auto-columns: minmax(0px, 1fr); */
    display: grid;
    /* gap: 24px; */
    margin: 46px;
    padding: 25px;
    justify-content: space-evenly;
}

.top_tools_colums {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    gap: 8px;
    margin: 0px;
    padding: 0px;
}

.tools_head_name {
    color: rgb(117, 117, 117);
    font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.tools_head_tool {
    display: flex;
    flex-flow: column wrap;
    flex: 1 1 auto;
    gap: 8px;
    margin: 0px;
    padding: 0px;
}

.tools {
    position: relative;
    display: flex;
    list-style-type: none;
}

.tool_flex {
    display: flex;
    flex: 1 1 auto;
}

.tool_center {
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.tool_top {
    font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    padding: 8px 8px 8px 8px;
    color: rgb(26, 26, 26);
    border-radius: 4px;
}

.tool_top:hover {
    background-color: #f8f7ff;
    cursor: pointer;
}

.tool_name {
    padding-left: 20px;
}
.head_sub_names{
    margin-left: 8px;
    font-weight: 600;
    background-color: rgba(37, 144, 102, .09);
    color: #259066;
    padding: 0.1em 0.3em 0.15em;
    font-size: 10px;
}