.field-content {
    width: 100%;
    height: 100%;
    border-left: 1px solid var(--border-color);
}

.recipients-wrapper {
    text-align: center;
    padding: 4px;
    width: 20%;
}

.fields-container {
    width: 100%;
}

.space-vertical {
    width: 2%;
    background-color: #F9F9FB;
}

.pdf-drag-field {
    width: 100%;
    height: 100%;
}

.pdf-fheader {
    font-family: var(--primary-font-family);
    font-size: 11.375px;
    font-style: normal;
    font-weight: 600;
    line-height: 13.65px;
    color: var(--table-header-font);
    text-transform: uppercase;
    padding: 14px 20px;
    gap: 174.738px;
    border-bottom: 1px solid var(--border-color);
    background: #F8FAFD;
}

.add-icon {
    color: var(--primary);
    font-weight: 600;
    font-size: 16px;
    margin-right: 1px;
}

.pdf-field-propertise {
    display: inline-flex;
    list-style: none;
    width: 40%;
    margin: 5px 6px;
    vertical-align: top;
    font-size: 13px;
    color: var(--table-font-color);
    align-items: center;
    justify-content: flex-start;
    padding: 9px;
    border-radius: 3px;
    cursor: move;
    user-select: none;
    font-weight: 500;
    white-space: nowrap;
    z-index: 1;
}

.pdf-field-propertise-disabled {
    display: inline-flex;
    list-style: none;
    width: 40%;
    margin: 5px 6px;
    vertical-align: top;
    font-size: 13px;
    color: var(--table-font-color);
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    border-radius: 3px;
    cursor: no-drop;
    user-select: none;
    font-weight: 500;
    white-space: nowrap;
    z-index: 1;
}

.pdf-fheader-field {
    width: 100%;
    font-family: var(--primary-font-family);
    font-size: 11.375px;
    font-style: normal;
    font-weight: 600;
    line-height: 13.65px;
    color: #8C98A4;
    text-transform: uppercase;
    padding: 14px 20px;
    gap: 174.738px;
    border-bottom: 1px solid var(--border-color);
    background: #F8FAFD;
}

.pdf-field-propertise:hover {
    font-family: var(--primary-font-family);
    color: var(--font-color);
    background: #BDC5D133;
}

.pdf-field-propertise-text {
    margin-left: 10px !important;
}

.fields_container {
    overflow: scroll;
    overflow-x: hidden;
    height: calc(100vh - 100px);
    padding-bottom: 20px;
}

.fields_container::-webkit-scrollbar-track {
    background-color: inherit !important;
}

.fields_container::-webkit-scrollbar {
    max-height: 12px !important;
    max-width: 5px !important;
    min-height: 8px !important;
    min-width: 5px !important;
    background-color: #ffffff !important;
}

.fields_container::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px #0000004d !important;
    background: #b8b8b8 !important;
    border-radius: 5px !important;
    width: 5px !important;
    height: 5px !important;
}

.pdf-board-field-propertise-text {
    margin-left: 10px !important;
    text-overflow: ellipsis;
    display: inline-block;
    width: 80px;
    overflow: hidden;
    white-space: nowrap;
}

.pdf-fields-wrapper {
    padding: 0;
    right: 0;
    bottom: 0;
    display: inline-block;
    width: 100%;
    background: #ffffff;
}

.pdf-field-propertise .react-draggable .react-draggable-dragged {
    position: absolute !important;
}

.field-container {
    width: 100%;
    display: table;
    table-layout: fixed;
    overflow: hidden;
    padding: 5px 14px 0 20px;
    box-sizing: border-box;
    text-align: start;
    justify-content: center;
    line-height: 30px;
}

.checkbox {
    display: inline-block;
    width: 20px;
    margin-right: 5px;
    vertical-align: middle;
    padding-bottom: 0;
    border-radius: 0;
}

.required-field span {
    color: var(--table-font-color);
    width: unset;
    font-size: 13px;
    font-weight: 500;
    padding: 0px 10px 0px 0px !important;
}

.required-field {
    overflow: scroll;
    height: calc(100vh - 200px);
}

.required-field::-webkit-scrollbar {
    display: none;
}

.field-input {
    width: 100%;
    height: 33px;
    margin: 0;
    padding: 0px 4px;
    font-size: 13px;
    display: inline-block;
    border: 1px solid var(--border-color);
    border-radius: 3px;
    box-sizing: border-box;
    vertical-align: top;
}

.field-input:focus {
    outline: 0 !important;
}

.field-select-input {
    width: 60%;
    padding: 8px 5px;
    color: var(--table-font-color);
    margin: 0;
    font-size: 13px;
    display: inline-block;
    border: 1px solid var(--border-color);
    border-radius: 3px;
    box-sizing: border-box;
    vertical-align: top;
}

.field-width {
    width: 80px;
    padding: 8px 5px;
    margin: 0;
    font-size: 13px;
    display: inline-block;
    border: 1px solid var(--border-color);
    border-radius: 3px;
    box-sizing: border-box;
    margin-top: 8px;
    vertical-align: top;
}

.field-width:focus {
    outline: 0 !important;
}

.fheader {
    font-size: 14px;
    color: var(--table-font-color);
    font-weight: 500;
    text-align: left;
    margin-left: 0;
    margin-top: 4px;
}

.fheader-radio {
    font-size: 14px;
    color: var(--table-font-color);
    font-weight: 500;
    text-align: left;
    margin-left: 0;
    margin-top: 4px;
    margin-bottom: -7px;
}

.dimension-row {
    display: flex;
    flex-direction: row;
    padding: 5px 14px 0 0;
    text-align: initial;
}

.width-fheader {
    font-size: 13px;
    display: block;
    color: var(--table-font-color);
    font-weight: 500;
    text-align: left;
    margin-left: 0;
}

.delete-container {
    text-align: center;
    border: 1px solid #ed4c78;
    border-radius: 4px;
    width: 110px;
    margin: 0 auto;
    padding: 0;
    margin-bottom: 40px;
}

.delete-field {
    border: none;
    font-size: 13px;
    background-color: #ffffff !important;
    padding: 4px;
    color: #ed4c78;
    display: block;
    border-radius: 3px;
}

.delete-field:hover {
    border: none;
    font-size: 13px;
    background-color: #ed4c78 !important;
    padding: 4px;
    color: #ffffff;
    display: block;
    cursor: pointer;
    border-radius: 3px;
}

.close-icon {
    display: flex;
    float: right;
    opacity: 1;
    cursor: pointer;
    margin-left: 0;
}

.close-icon:hover {
    display: flex;
    float: right;
    opacity: 1;
    cursor: pointer;
    margin-left: 0;
    color: rgb(51, 51, 51);
}

.field-properties {
    overflow: scroll;
}

.list-input-field {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin-top: 8px;
}

.list-select-button {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    padding-left: 0;
    border: 1px solid var(--border-color);
    vertical-align: top;
    border-radius: 50%;
    margin: -4px 7px 0 0;
    background-color: #FFFFFF;
}

.field-add-delete {
    position: static;
    top: 17px;
    right: 0px;
    font-size: 30px;
    display: block;
    width: 17px;
    height: 15px;
    color: var(--primary);
    z-index: 1;
    font-weight: 300;
    line-height: 10px;
    cursor: pointer;
    margin-left: 10px;
}

.modal-mask {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #13214440;
    display: table;
    text-align: center;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-container {
    width: 60%;
    margin: 0px auto;
    padding: 15px 30px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
}

.modal-body {
    margin: 5px 0px 15px 0px;
}

.modal-body::-webkit-scrollbar {
    display: none;
}

.row-container {
    display: flex;
    align-items: center;
    font-size: 13px;
    width: 100%;
}

.recipient {
    width: 100%;
    position: relative;
}

.recipient-text {
    display: flex;
}

.recipient-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-color);
}

.recipient-header {
    text-align: initial;
    padding: 10px 10px 10px 0px;
    color: var(--table-font-color);
    font-family: var(--primary-font-family);
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 25.2px;
}

.recipient-signer {
    display: flex;
}

.signer-wrapper {
    color: var(--table-header-font);
    font-family: var(--primary-font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.select-signer {
    display: flex !important;
    font-size: 14px !important;
    align-items: center !important;
    margin: 5px 9px 0px 0px !important;
    font-family: var(--primary-font-family);
    font-style: normal !important;
    font-weight: 400 !important;
    color: var(--table-font-color) !important;
}

.select-signing-order {
    display: flex !important;
    font-size: 14px !important;
    align-items: center !important;
    margin: 5px 9px 0px 0px !important;
    opacity: 0.4 !important;
    cursor: not-allowed !important;
    font-style: normal !important;
    font-weight: 400 !important;
    color: var(--common-title) !important;
}

.recipient-body {
    width: 100%;
    padding: 0px 0px 10px 1px;
    align-items: flex-start;
    overflow: scroll;
}

.recipient-body::-webkit-scrollbar-track {
    background-color: inherit;
}

.recipient-body::-webkit-scrollbar {
    max-height: 12px;
    max-width: 5px;
    min-height: 8px;
    min-width: 5px;
    background-color: #ffffff;
}

.recipient-body::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px #0000004d;
    background: #b8b8b8;
    border-radius: 5px;
    width: 5px;
    height: 5px;
}

.recipient-input {
    margin-bottom: 30px;
    padding: 11.8px 16px 11.79px 16px;
    width: 100%;
}

.recipient-verification {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--table-font-color);
    font-size: 14px;
    margin-top: 15px;
}

.recipient-passcode-body {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 15px;
    color: var(--table-font-color);
}

.input-container {
    width: 45%;
    margin: 10px 0px 7px 0px;
}

.name-container {
    width: 35%;
    margin: 10px 0px 7px 0px;
}

.input {
    width: 85%;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    resize: vertical;
    padding: 10px;
    outline-color: var(--primary);
    outline: none;
}

.input:focus {
    width: 85%;
    border: 1px solid var(--primary);
    border-radius: 5px;
    resize: vertical;
    padding: 10px;
    outline: none;
}

.error-input {
    width: 85%;
    border: 1px solid rgb(227, 63, 72);
    border-radius: 5px;
    resize: vertical;
    padding: 10px;
}

@media only screen and (max-width: 1150px) {
    .input {
        width: 75%;
        border: 1px solid var(--border-color);
        border-radius: 5px;
        resize: vertical;
        padding: 10px;
        outline: none;
    }

    .input:focus {
        width: 75%;
        border: 1px solid var(--primary);
        border-radius: 5px;
        resize: vertical;
        padding: 10px;
    }

    .error-input {
        width: 75%;
        border: 1px solid rgb(227, 63, 72);
        border-radius: 5px;
        resize: vertical;
        padding: 10px;
    }
}

.modal-button {
    font-weight: 400 !important;
    text-transform: capitalize !important;
    color: #fff !important;
    border-radius: 5px;
    padding: 6px 16px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    margin: 0 10px !important;
    box-shadow: none !important;
    font-size: 14px !important;
    font-family: var(--primary-font-family) !important;
}

.close_wrapper {
    display: flex;
    align-items: flex-end;
}

.modal-button:hover {
    font-weight: 400;
    color: #fff;
    border-radius: 5px;
    /* background: var(--primary); */
    padding: 6px 16px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 10px;
    font-size: 14px;
    cursor: pointer;
    border: none !important;
    font-family: var(--primary-font-family) !important;
    box-shadow: 0 0.1875rem 0.75rem #b4c8ec;
}

.add-button {
    display: flex;
}

.add {
    color: #23ab94;
    cursor: pointer;
}

.remove {
    color: #ff6868;
    margin-left: 15px;
}

.recipient-container {
    overflow-y: scroll !important;
    height: 60%;
}

.recipient-container::-webkit-scrollbar {
    display: none;
}

.email-container {
    display: flex;
    width: 100%;
    cursor: pointer;
    height: 50px;
    justify-content: center;
    text-align: center;
    margin-right: 7px;
}

.left-border {
    width: 5px;
    height: 50px;
}

.circle-text {
    border-radius: 50%;
    color: #616161;
    width: 35px;
    height: 35px;
    margin-top: 10px;
}

.text {
    font-weight: 600;
    font-size: 14px;
    padding: 8px;
    color: var(--primary);
}

.active-text {
    font-weight: 600;
    font-size: 14px;
    padding: 8px;
    color: #FFFFFF;
}

.text-container {
    text-align: start;
    margin-left: 10px;
}

.email-text {
    color: var(--primary);
    font-weight: 400;
    font-size: 13px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 180px;
}

.basic-field-container {
    width: 100%;
    margin: 10px 0px 0px 30px;
}

.Toastify__close-button {
    color: #f9441a;
}

.toast-message {
    background: '#fde9e9';
    color: '#f9441a';
    border: '1px solid #f9441a73';
    font-size: '14px';
    font-family: var(--primary-font-family);
    margin-top: '30px';
}

.Toastify__toast--error {
    border: 1px solid #FF9192;
    color: #A10E25 !important;
    background: #FFE9E8 !important;
    box-shadow: 0 0 10px 1px #ddddddc7 !important;
    font-size: 14px !important;
}

.close-icons {
    display: flex;
    align-items: center;
}

input[type='radio'] {
    accent-color: var(--primary);
}

.radio {
    width: 17px;
    height: 17px;
    border: 1px solid #9a9a9a;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    margin-right: 6px;
}

.radio-select {
    width: 12px;
    height: 12px;
    background-color: var(--primary) !important;
    color: var(--primary);
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    transition: opacity 0.2s;
    cursor: pointer;
    border: none;
}

.radio-select:focus {
    outline: none !important;
    border: 1px solid var(--primary) !important;
}

.checkbox {
    position: relative;
    display: inline-block;
    width: 13px;
    height: 13px;
}

.checkbox-select {
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow:
        2px 0 0 white,
        4px 0 0 white,
        4px -2px 0 white,
        4px -4px 0 white,
        4px -6px 0 white,
        4px -8px 0 white;
    transform: rotate(45deg);
}

.checkbox-custom-select {
    background-color: #FFFFFF;
    border: 1px solid var(--border-color);
    border-radius: 0px !important;
    margin-right: 5px;
}

.css-i4bv87-MuiSvgIcon-root {
    font-size: 1.6rem !important;
}

.icon-before-select {
    background: var(--primary);
    position: absolute;
    left: 0;
    top: 33%;
    height: 40%;
    width: 2px;
    content: "";
    transform: translateX(7px) rotate(-40deg);
    transform-origin: left bottom;
    border: none;
    cursor: pointer;
}

.icon-after-select {
    background: var(--primary);
    position: absolute;
    left: 0;
    bottom: 5px;
    height: 2px;
    width: 70%;
    content: "";
    transform: translateX(8px) rotate(-45deg);
    transform-origin: left bottom;
    cursor: pointer;
}

.custom-icon {
    border-radius: 0;
    width: 18px;
    height: 18px;
    line-height: unset;
    padding: 0;
    color: transparent;
    position: relative;
}

.basic-fields-img {
    width: 25px;
    height: 25px;
    padding: 4px;
    background: rgba(236, 242, 255, 0.30);
    border-radius: 3px;
}

.board_fields_img {
    width: 25px;
    height: 20px;
    padding: 4px;
    background: rgba(236, 242, 255, 0.30);
    border-radius: 3px;
}

.select-img {
    width: 17px;
    height: 17px;
    display: flex;
    justify-content: center;
}

.custom-field-container {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.custom-field-body {
    display: flex;
    width: 178px;
    padding: 10px;
    border: 1.5px solid rgba(231, 234, 243, 0.70);
    background-color: #FFF;
    cursor: pointer;
    border-radius: 3px;
}

.custom-field-text {
    color: var(--primary);
    text-align: center;
    font-family: var(--primary-font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    margin-right: 7px;
}

.menu-item-img {
    width: 16px;
    height: 16px;
    margin-left: 2px;
    flex-shrink: 0;
}

.signer-img {
    width: 11px;
    height: 11px;
    flex-shrink: 0;
    margin-left: 3px;
    margin-top: -10px;
}

.plus-img {
    width: 5px;
    height: 5px;
    margin-top: 10px;
}

.accordion_header {
    width: 33% !important;
    flex-shrink: 0;
    font-size: 28px !important;
}

.accordion_container {
    border-bottom: 2px solid #cbd0e06e;
}

.accordion_body {
    margin: 25px !important;
}

.upload_select {
    width: 160px;
    height: 32px;
    outline: none;
    /* border: 1px solid #e2e3e5; */
    background-color: var(--primary);
    border-radius: 3px;
    color: #fff !important;
}

.upload_select svg {
    color: #fff;
}

.next_btn {
    display: flex !important;
    flex-direction: column-reverse !important;
    justify-content: flex-end !important;
    border-radius: 6px !important;
    background-color: var(--primary) !important;
    cursor: pointer;
    color: #fff !important;
    font-weight: 500 !important;
    width: 100px;
}

.add_recepient_container {
    background-color: #fff;
    border-radius: 0px 11px 11px 0px;
    border-top: 1.5px solid var(--border-color);
    border-right: 1.5px solid var(--border-color);
    border-bottom: 1.5px solid var(--border-color);
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px; */
    padding: 20px 20px 20px 0px;
    display: flex;
}

.add_recepient_label {
    display: flex !important;
    width: 100%;
    justify-content: flex-start !important;
    margin-bottom: 10px;
    color: var(--table-font-color) !important;
    font-family: var(--primary-font-family) !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
}

.add_message_container {
    width: 100%;
    min-width: 460px !important;
    cursor: text;
    border: 1px solid #C9C9C9;
    min-height: 68px;
    border-radius: 4px;
    max-width: 461px !important;
    position: relative;
}

.color_container {
    width: 11px;
    border-radius: 11px 0px 0px 11px;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 5px !important;
}

.recipient_index {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-right: 10px !important;
}

.signSelect {
    width: 30%;
    height: 32px;
    margin-left: 20px;
    margin-top: 28px;
    z-index: 999;
    font-size: 14px !important;
}

.approver_select {
    width: 30%;
    height: 32px;
    margin-left: 20px;
    z-index: 999;
    font-size: 14px !important;
}

.recipient_delete {
    margin-left: 20px;
    margin-top: 29px;
    cursor: pointer;
}

.recipient_passcode {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
    color: var(--table-font-color);
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    margin-bottom: 15px !important;
}

.add_message {
    display: flex;
    justify-content: center;
}

.passcode {
    width: 80%;
    margin-top: 15px;
    height: 33px;
    font-size: 14px !important;
    position: absolute;
}

.verification-input {
    padding: 7px;
    border: 1px solid #E9EBF2;
    border-radius: 5px;
    margin-top: 15px;
    font-size: 14px;
    width: 100%;
}

.verification-input:focus {
    outline: none
}

.verification-input::placeholder {
    font-size: 14px;
    color: #62637a;
    font-weight: 400 !important;
}

.need-to-view {
    z-index: 9999 !important;
    font-family: var(--primary-font-family) !important;
    font-size: 13px !important;
    gap: 10px !important;
    font-family: var(--primary-font-family) !important;
    color: var(--table-font-color) !important;
}

.recipients-add {
    border-radius: 6px;
    text-transform: capitalize !important;
    display: flex;
    width: 150px;
    align-items: center;
    gap: 4px;
    justify-content: center;
    cursor: pointer;
    border: 1px solid var(--primary);
}

.recipient-add-text {
    color: var(--primary);
    font-family: var(--primary-font-family) !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 4px;
}

.recipient-proceed-img {
    width: 14px;
    height: 14px;
    margin-left: 5px;
}

.recipient-add-img {
    width: 14px;
    height: 14px;
}

.bottom-container {
    display: flex;
    width: 100%;
    margin-left: 3px;
    justify-content: space-between;
}

.sign-bottom-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin: 10px 0px 0px 0px;
}

.recipient-input-container {
    display: flex;
    justify-content: center;
    margin: 8px 4px 4px 4px;
}

.menu-item {
    text-align: left;
    margin: 0;
    opacity: 1;
    vertical-align: top;
    border-radius: 5px;
    font-size: 13px !important;
    cursor: pointer;
    color: #fff;
}

.react-switch-handle {
    height: 15px !important;
    width: 15px !important;
}

.input_box_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 0px 10px 0px;
}

.approvel_box_container {
    display: flex;
    width: 100%;
    padding: 0px 0px 10px 0px;
}

.drag_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    cursor: grabbing;
}

.button-container {
    display: flex;
}

.recipient_sign_order {
    display: flex !important;
    align-items: center !important;
}

.sign_order_input {
    width: 30px !important;
    display: flex !important;
}

.css-1x5jdmq {
    padding: 10px 6px !important;
}

.recipient-select-container {
    border-radius: 8px !important;
    background: #FFF !important;
    margin-top: 5px !important;
    box-shadow: var(--box-shadow) !important;
    border: 1px solid var(--border-color) !important;
}

.recipient-select-container .css-hy0utx-MuiButtonBase-root-MuiMenuItem-root:hover,
.recipient-select-container .css-hy0utx-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
    color: var(--primary) !important;
}

.recipient-select-container .css-hy0utx-MuiButtonBase-root-MuiMenuItem-root:hover img,
.recipient-select-container .css-hy0utx-MuiButtonBase-root-MuiMenuItem-root.Mui-selected img {
    filter: invert(38%) sepia(88%) saturate(1642%) hue-rotate(205deg) brightness(99%) contrast(104%) !important;

}

.recipient-select-container .css-1kwtie9:hover,
.recipient-select-container .css-1kwtie9.Mui-selected {
    color: var(--primary) !important;
}

.recipient-select-container .css-1kwtie9:hover img,
.recipient-select-container .css-1kwtie9.Mui-selected img {
    filter: invert(38%) sepia(88%) saturate(1642%) hue-rotate(205deg) brightness(99%) contrast(104%) !important;
}

.signer-select-img {
    width: 12px;
    height: 12px;
    flex-shrink: 0;
}

.signer-active-img {
    width: 11px;
    height: 11px;
    flex-shrink: 0;
}

.field_modal_result {
    position: absolute !important;
    /* max-height: 300px !important; */
    max-height: 200px !important;
    width: 100% !important;
    border-radius: 5px !important;
    box-shadow: 0 0.6125rem 2.5rem 0.6125rem #8c98a42e !important;
    overflow-x: unset !important;
    overflow-y: scroll !important;
    background-color: #fff !important;
    border: 1px solid #e7eaf3;
    z-index: 9999 !important;
}

.field_modal_result::-webkit-scrollbar-track {
    background-color: inherit !important;
}

.field_modal_result::-webkit-scrollbar {
    max-height: 12px !important;
    max-width: 5px !important;
    min-height: 8px !important;
    min-width: 5px !important;
    background-color: #ffffff !important;
}

.field_modal_result::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px #0000004d !important;
    background: #b8b8b8 !important;
    border-radius: 5px !important;
    width: 5px !important;
    height: 5px !important;
}

.field_modal_result_drop {
    position: absolute !important;
    max-height: 200px !important;
    width: 100% !important;
    border-radius: 5px !important;
    box-shadow: 0 0.6125rem 2.5rem 0.6125rem #8c98a42e !important;
    background-color: #fff !important;
    border: 1px solid #e7eaf3;
    overflow-x: unset !important;
    overflow-y: scroll !important;
    z-index: 9999 !important;

}

.field_modal_result_drop::-webkit-scrollbar-track {
    background-color: inherit !important;
}

.field_modal_result_drop::-webkit-scrollbar {
    max-height: 12px !important;
    max-width: 5px !important;
    min-height: 8px !important;
    min-width: 5px !important;
    background-color: #ffffff !important;
}

.field_modal_result_drop::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px #0000004d !important;
    background: #b8b8b8 !important;
    border-radius: 5px !important;
    width: 5px !important;
    height: 5px !important;
}

.field_dropDown_list {
    text-align: left !important;
    padding: 16px 16px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #193037 !important;
    cursor: pointer !important;
}

.field_dropDown_list:hover {
    background-color: rgba(189, 197, 209, .2) !important;
}

.field_keyDown_list {
    background-color: fff !important;
    text-align: left !important;
    padding: 12px 12px !important;
    margin: 4px 0px 4px 4px;
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #193037 !important;
    cursor: pointer !important;
}

.field_keyDown_list:hover {
    background-color: rgba(189, 197, 209, .2) !important;
}

.select-field-body {
    margin: 5px !important;
    min-height: 35px !important;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 6px !important;
}

.formatting-field-body {
    margin: 5px !important;
    min-height: 35px !important;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 6px !important;
}

.recipient_select_dropdown .css-6hp17o-MuiList-root-MuiMenu-list {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.recipient_select_dropdown {
    width: 100%;
    height: 35px;
    border: 1px solid var(--border-color);
}

.select-field-body:has(:hover) {
    background-color: rgba(189, 197, 209, 0.2) !important
}

.select-field-body:hover {
    background-color: rgba(189, 197, 209, 0.2) !important;
    margin: 5px !important;
    display: flex;
    /* border-radius: 8px; */
    align-items: center;
    cursor: pointer;
}

.select-field-body>span {
    font-family: var(--primary-font-family);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.5px;
}

.approval_name_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.divider_wrapper {
    margin: 15px 0px 15px 8px !important;
    width: 98% !important;
}

.approver_close {
    padding: 2px !important;
    position: absolute !important;
    margin-right: 5px;
    cursor: pointer;
    z-index: 99999 !important;
}

.approver_text_field {
    display: flex;
    align-items: center;
    justify-content: right;
}

.crm_fheader {
    font-family: var(--primary-font-family);
    font-size: 11.375px;
    font-style: normal;
    font-weight: 600;
    line-height: 13.65px;
    color: var(--table-header-font);
    text-transform: uppercase;
    padding: 20px 20px 0px 20px;
    gap: 174.738px;
    /* border-bottom: 1px solid var(--border-color); */
}

.formating_body {
    border: 1px solid var(--border-color);
    width: 90%;
    margin-top: 10px;
}

.formating_wrapper {
    background: #fff;
    padding: 0px;
    border-radius: 8px;
    padding: 5px;
    display: flex;
    gap: 10px;
}

.formating_btn {
    min-width: 48px !important;
}

.formating_btn:active {
    background-color: var(--primary);
}

.formating_btns {
    min-width: 48px !important;
    border: none !important;
    border-radius: 0px !important;
}

.formating_size_btn {
    border: 1px solid var(--border-color);
}

.pagination_item {
    display: flex !important;
    justify-content: center !important;
    font-family: var(--primary-font-family) !important;
}

.select_pagination {
    box-shadow: var(--box-shadow) !important;
}

.formatting_field {
    font-size: 13px !important;
    font-weight: 400 !important;
}

.sign_btn {
    height: 42.59px !important;
    text-transform: capitalize !important;
    border-radius: 6px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer !important;
    color: #fff !important;
    font-weight: 500 !important;
    font-family: var(--primary-font-family) !important;
    font-size: 14px !important;
}